import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Container } from '../../components/container';
import { Wrapper, KeyPointsContainer, KeyPointsTitle } from './home.styles';

export const Home: FC = () => {
  const { t } = useTranslation('home');
  return (
    <Wrapper>
      <Container>
        <h3>{t('welcome')}</h3>
        {Object.values(t('sections', { returnObjects: true })).map(
          (item: {
            title: string;
            content: {
              title: string;
              description: string;
            }[];
          }) => {
            return (
              <div key={item.title}>
                <KeyPointsTitle>{item.title}</KeyPointsTitle>
                <KeyPointsContainer>
                  {item.content.map(
                    (content: { title: string; description: string }) => {
                      return (
                        <div key={content.title}>
                          <h6>{content.title}</h6>
                          <p>{content.description}</p>
                        </div>
                      );
                    },
                  )}
                </KeyPointsContainer>
              </div>
            );
          },
        )}
      </Container>
    </Wrapper>
  );
};
