import { Portfolio } from '../types/portfolio';

export const EMPTY_PORTFOLIO: Omit<Portfolio, 'id' | 'user'> = {
  headline: '',
  location: {
    city: '',
    country: '',
  },
  occupation: '',
  skills: [],
  languages: [
    {
      language: '',
      proficiency: '',
    },
  ],
  experiences: [],
  educations: [],
};
