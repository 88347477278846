import { Page } from '../../components/layouts/page';

export const PrivacyPolicy = () => {
  return (
    <Page>
      <h3>Privacy Policy</h3>
      <ul>
        <li>
          <strong>Last updated:</strong> October 2024
        </li>
        <li>
          Talentsburg ("we," "our," or "us") is committed to protecting the
          privacy and security of your personal information. This Privacy Policy
          explains how we collect, use, disclose, and safeguard your information
          when you use our platform. Please read this policy carefully to
          understand our practices regarding your personal data.
        </li>
      </ul>

      <h4>1. Information We Collect</h4>
      <ul>
        <li>
          <strong>a) Information Provided by Talents:</strong>
          <ul>
            <li>
              <strong>Personal details:</strong> Name, contact information
              (email, phone number), and address.
            </li>
            <li>
              <strong>Professional information:</strong> CV/resume, educational
              background, work experience, skills, portfolio links,
              certifications, and any other information you include in your
              profile.
            </li>
            <li>
              <strong>Application data:</strong> When you apply for jobs, we
              store your applications, cover letters, and communications with
              hiring managers.
            </li>
          </ul>
        </li>
        <li>
          <strong>b) Information Provided by Hiring Managers:</strong>
          <ul>
            <li>
              <strong>Company details:</strong> Name, company email, and company
              description.
            </li>
            <li>
              <strong>Job details:</strong> Job titles, descriptions, locations,
              and qualifications.
            </li>
            <li>
              <strong>Interactions:</strong> Communications with talents,
              including interview invitations, feedback, and hiring decisions.
            </li>
          </ul>
        </li>
        <li>
          <strong>c) Automatically Collected Information:</strong>
          <ul>
            <li>
              <strong>Device and usage information:</strong> IP address, browser
              type, operating system, and usage patterns.
            </li>
            <li>
              <strong>Cookies and similar technologies:</strong> We use cookies
              to track your activity on our platform, remember preferences, and
              gather analytics data to improve our service.
            </li>
          </ul>
        </li>
      </ul>

      <h4>2. How We Use Your Information</h4>
      <ul>
        <li>
          We use the information we collect for the following purposes:
          <ul>
            <li>
              <strong>Providing and improving services:</strong> To create and
              maintain your account, help you build a profile, connect you with
              job opportunities, and manage applications.
            </li>
            <li>
              <strong>Communications:</strong> To send you notifications,
              updates, and promotional content related to jobs or opportunities
              on the platform.
            </li>
            <li>
              <strong>Job matching:</strong> We use algorithms to match talents
              with job postings based on the information in their profiles and
              resumes.
            </li>
            <li>
              <strong>Analytics and improvements:</strong> To understand how
              users interact with the platform and improve user experience.
            </li>
            <li>
              <strong>Legal obligations:</strong> To comply with legal and
              regulatory requirements.
            </li>
          </ul>
        </li>
      </ul>

      <h4>3. How We Share Your Information</h4>
      <ul>
        <li>
          We may share your information with the following parties:
          <ul>
            <li>
              <strong>Hiring Managers:</strong> When you apply for a job, your
              profile, resume, and application materials will be shared with the
              relevant hiring manager or employer.
            </li>
            <li>
              <strong>Third-party service providers:</strong> We may use
              third-party companies to assist in providing our services, such as
              hosting services, payment processors, or analytics providers.
              These providers are required to maintain the confidentiality of
              your data.
            </li>
            <li>
              <strong>Legal purposes:</strong> We may disclose your data if
              required by law, such as in response to a legal process or
              government request.
            </li>
          </ul>
        </li>
      </ul>

      <h4>4. Data Security</h4>
      <ul>
        <li>
          We implement reasonable security measures to protect your personal
          data from unauthorized access, alteration, disclosure, or destruction.
          However, no method of transmission over the internet or method of
          electronic storage is 100% secure, and we cannot guarantee absolute
          security.
        </li>
      </ul>

      <h4>5. Your Rights and Choices</h4>
      <ul>
        <li>
          You have the following rights concerning your personal information:
          <ul>
            <li>
              <strong>Access and correction:</strong> You may access and update
              your profile information at any time by logging into your account.
            </li>
            <li>
              <strong>Deletion:</strong> You may request that we delete your
              account and all associated data, subject to certain legal or
              contractual obligations.
            </li>
            <li>
              <strong>Communication preferences:</strong> You can opt-out of
              promotional communications by following the unsubscribe
              instructions in the emails we send you.
            </li>
            <li>
              <strong>Cookies:</strong> You can manage your cookie preferences
              through your browser settings.
            </li>
          </ul>
        </li>
      </ul>

      <h4>6. Data Retention</h4>
      <ul>
        <li>
          We retain your personal data for as long as your account is active or
          as needed to provide you services. If you close your account, we may
          retain certain information to comply with legal obligations, resolve
          disputes, or enforce our agreements.
        </li>
      </ul>

      <h4>7. International Data Transfers</h4>
      <ul>
        <li>
          Talentsburg is based in [Your Country], but we may process data in
          other countries, depending on the location of our service providers.
          By using our platform, you consent to the transfer of your information
          to countries outside your own, including countries that may have
          different data protection laws.
        </li>
      </ul>

      <h4>8. Changes to This Privacy Policy</h4>
      <ul>
        <li>
          We may update this Privacy Policy from time to time. Any changes will
          be effective when posted, and we will notify you via email or through
          the platform if there are significant updates. We encourage you to
          review this policy periodically.
        </li>
      </ul>
    </Page>
  );
};
