import { FC } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { experienceSchema } from '../../../schemas/experience.schema';
import { Experience as ExperienceType } from '../../../types/experience';
import { DatePicker } from '../../../components/form-elements/datepicker';
import { TextInput } from '../../../components/form-elements/text-input';
import { Button } from '../../../components/form-elements/button';
import { Dropdown } from '../../../components/form-elements/dropdown';
import { Trash } from '../../../components/icons/common/trash';
import { Add } from '../../../components/icons/common/add';
import { ProfileModal } from '../../../components/profile-modal/profile-modal';
import {
  ContentRow,
  LeftContent,
  RightContent,
} from '../../../components/profile-modal/profile-modal.styles';
import {
  createExperience,
  deleteExperience,
  updateExperience,
} from '../../../api/portfolio';
import { Portfolio } from '../../../types/portfolio';
import { TagList } from '../../../components/tag-list';
import {
  MAX_EXPERIENCE_RESPONSIBILITIES_NUMBER,
  MIN_EXPERIENCE_RESPONSIBILITIES_NUMBER,
} from '../../../constants/experience';

interface IProps {
  portfolio: Portfolio;
  organizations: {
    value: string;
    label: string;
  }[];
  experience: ExperienceType;
  onCancel: () => void;
  onSave: () => void;
  onDelete?: () => void;
}

export const ExperienceForm: FC<IProps> = ({
  portfolio,
  organizations,
  experience,
  onCancel,
  onSave,
}) => {
  const { t } = useTranslation('profile');
  const { mutate: create } = useMutation(
    async (data: Omit<ExperienceType, 'id'>) => {
      await createExperience(portfolio.id, data);
      onSave();
    },
  );

  const { mutate: update } = useMutation(async (data: ExperienceType) => {
    await updateExperience(portfolio.id, data.id!, data);
    onSave();
  });

  const { mutate: del } = useMutation(async (experienceId?: string) => {
    if (experienceId) {
      await deleteExperience(portfolio.id, experienceId);
    }
    onSave();
  });

  const { control, formState, reset, getValues, setValue, handleSubmit } =
    useForm<yup.InferType<typeof experienceSchema>>({
      mode: 'onChange',
      resolver: yupResolver(experienceSchema),
      defaultValues: experience,
    });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'responsibilities',
  });

  return (
    <ProfileModal
      onCancel={() => {
        reset();
        onCancel();
      }}
      onDelete={
        experience.id
          ? () => {
              if (experience.id) {
                const { id } = getValues();
                del(id);
              } else {
                onCancel();
              }
            }
          : undefined
      }
      onSave={handleSubmit((data) => {
        if (data.id === undefined) {
          create(data);
        } else {
          update(data as ExperienceType);
        }
      })}
    >
      <ContentRow>
        <LeftContent>
          <h4>{t('experience.title')}</h4>
        </LeftContent>
        <RightContent>
          <h6>{t('experience.organization')}</h6>
          <Controller
            name="organization"
            control={control}
            render={({ field }) => {
              return (
                <Dropdown
                  placeholder={t('experience.organization')}
                  options={organizations}
                  onChange={(option) => {
                    field.onChange(option.value, {
                      shouldValidate: true,
                    });
                  }}
                  value={field.value}
                  allowNew
                  error={formState.errors.organization?.message}
                />
              );
            }}
          />
          <h6>{t('experience.position')}</h6>
          <Controller
            name="position"
            control={control}
            render={({ field }) => {
              return (
                <TextInput
                  {...field}
                  placeholder={t('experience.position')}
                  error={formState.errors.position?.message}
                />
              );
            }}
          />
          <h6>{t('tenure.title')}</h6>
          <div
            style={{
              display: 'flex',
              gap: '12px',
            }}
          >
            <div style={{ flex: 1 }}>
              <Controller
                name="tenure.from"
                control={control}
                render={({ field }) => {
                  return (
                    <DatePicker
                      {...field}
                      placeholder={t('tenure.from')}
                      error={formState.errors.tenure?.from?.message}
                    />
                  );
                }}
              />
            </div>
            <div style={{ flex: 1 }}>
              <Controller
                name="tenure.to"
                control={control}
                render={({ field }) => {
                  return (
                    <DatePicker
                      {...field}
                      placeholder={t('tenure.to')}
                      error={formState.errors.tenure?.to?.message}
                    />
                  );
                }}
              />
            </div>
          </div>
          <h6>{t('translation:location')}</h6>
          <div
            style={{
              display: 'flex',
              gap: '12px',
            }}
          >
            <div style={{ flex: 1 }}>
              <Controller
                name="location.city"
                control={control}
                render={({ field }) => {
                  return (
                    <TextInput
                      {...field}
                      placeholder={t('translation:city')}
                      error={formState.errors.location?.city?.message}
                    />
                  );
                }}
              />
            </div>
            <div style={{ flex: 1 }}>
              <Controller
                name="location.country"
                control={control}
                render={({ field }) => {
                  return (
                    <TextInput
                      {...field}
                      placeholder={t('translation:country')}
                      error={formState.errors.location?.country?.message}
                    />
                  );
                }}
              />
            </div>
          </div>
          <h6>{t('experience.activities')}</h6>

          {fields.map((responsibility, index: number) => {
            return (
              <div
                key={responsibility.id}
                style={{
                  display: 'flex',
                  gap: '12px',
                  alignItems: 'start',
                  marginBottom: '12px',
                }}
              >
                <Controller
                  name={`responsibilities.${index}`}
                  control={control}
                  render={({ field }) => {
                    return (
                      <TextInput
                        onChange={(e) => {
                          field.onChange(
                            {
                              responsibility: e.target.value,
                            },
                            { shouldValidate: true },
                          );
                        }}
                        value={field.value.responsibility}
                        error={
                          formState.errors.responsibilities?.[index]
                            ?.responsibility?.message
                        }
                        placeholder={t('experience.responsibility-details')}
                        style={{
                          marginBottom: 0,
                          flex: 1,
                        }}
                      />
                    );
                  }}
                />
                <Button
                  style={{
                    width: '50px',
                  }}
                  variant="outlined"
                  onClick={() => remove(index)}
                  disabled={
                    fields.length <= MIN_EXPERIENCE_RESPONSIBILITIES_NUMBER
                  }
                >
                  <Trash />
                </Button>
              </div>
            );
          })}
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              variant="outlined"
              style={{
                width: '50px',
              }}
              disabled={
                fields.length + 1 > MAX_EXPERIENCE_RESPONSIBILITIES_NUMBER
              }
              onClick={() => {
                append({
                  responsibility: '',
                });
              }}
            >
              <Add />
            </Button>
          </div>
          <h6>Skills</h6>
          <div>
            <Controller
              name="skills"
              control={control}
              render={({ field }) => {
                return (
                  <>
                    <Dropdown
                      placeholder="Skills"
                      options={(portfolio.skills ?? []).map((skill) => {
                        return {
                          value: skill,
                          label: skill,
                        };
                      })}
                      onChange={(option) => {
                        const arr = field.value ?? [];
                        if (!arr.includes(option.value)) {
                          arr.push(option.value);
                        }

                        setValue('skills', arr, {
                          shouldValidate: true,
                        });
                      }}
                    />
                    <TagList
                      tags={field.value ?? []}
                      removeTag={(tag) => {
                        setValue(
                          'skills',
                          field.value?.filter((t) => t !== tag),
                          {
                            shouldValidate: true,
                          },
                        );
                      }}
                    />
                  </>
                );
              }}
            />
          </div>
        </RightContent>
      </ContentRow>
    </ProfileModal>
  );
};
