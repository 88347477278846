import { FC, ReactNode } from 'react';
import styled, { css, DefaultTheme } from 'styled-components';

type Variant = 'danger' | 'warning' | 'info' | 'success';

const getStyles = (variant: Variant, theme: DefaultTheme) => {
  switch (variant) {
    case 'danger':
      return css`
        background-color: ${theme.palette.error.light};
        color: ${theme.palette.error.dark};
        border: 1px solid ${theme.palette.error.main};
      `;
    case 'warning':
      return css`
        background-color: ${theme.palette.warning.light};
        color: ${theme.palette.warning.dark};
        border: 1px solid ${theme.palette.warning.main};
      `;
    case 'info':
      return css`
        background-color: ${theme.palette.info.light};
        color: ${theme.palette.info.dark};
        border: 1px solid ${theme.palette.info.main};
      `;
    case 'success':
    default:
      return css`
        background-color: ${theme.palette.success.light};
        color: ${theme.palette.success.dark};
        border: 1px solid ${theme.palette.success.main};
      `;
  }
};

const Wrapper = styled.div<{ theme: DefaultTheme; variant: Variant }>(
  ({ theme, variant }) => css`
    padding: 12px;
    margin-bottom: 12px;
    ${getStyles(variant, theme)}
  `,
);

interface IProps {
  children: ReactNode;
  variant: Variant;
}

export const Alert: FC<IProps> = ({ children, variant }) => (
  <Wrapper variant={variant}>{children}</Wrapper>
);
