import styled, { css, DefaultTheme } from 'styled-components';

type Variant = 'text' | 'contained' | 'outlined';
type Size = 'small' | 'medium' | 'large';

const buttonSize = (size: Size | undefined) => {
  switch (size) {
    case 'small':
      return css`
        font-size: 14px;
        height: 40px;
      `;
    case 'medium':
      return css`
        font-size: 16px;
        height: 50px;
      `;
    case 'large':
      return css`
        font-size: 20px;
        height: 70px;
      `;
    default:
      return css``;
  }
};

const buttonVariant = (variant: Variant, theme: DefaultTheme) => {
  switch (variant) {
    case 'contained':
      return css`
        color: ${theme.palette.primary.dark};
        background-color: ${theme.palette.secondary.main};

        &:hover {
          background-color: ${theme.palette.secondary.dark};
        }
      `;
    case 'outlined':
      return css`
        color: ${theme.palette.text.primary};
        background-color: transparent;
        border: 1px solid ${theme.palette.divider};

        &:hover {
          background-color: ${theme.palette.background.paper};
        }
      `;
    case 'text':
    default:
      return css`
        color: ${theme.palette.text.primary};
        background-color: transparent;
      `;
  }
};

export type IProps = {
  variant?: Variant;
  size?: Size;
};

export const Button = styled.button<IProps>(
  ({ theme, variant = 'text', size = 'medium' }) => css`
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    padding: 6px 12px;
    border-radius: 4px;
    border: 1px solid transparent;

    &:hover {
      cursor: pointer;
    }

    &:disabled {
      opacity: 0.5;

      &:hover {
        cursor: not-allowed;
      }
    }

    ${buttonVariant(variant, theme)}
    ${buttonSize(size)}
  `,
);
