import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  margin-bottom: 12px;
`;

type TextFieldInputProps = {
  border?: boolean;
  error?: boolean;
};

export const Input = styled.input<TextFieldInputProps>(
  ({ theme, border = false, error = false }) => css`
    display: block;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    border-bottom: 1px solid ${theme.palette.divider};
    padding: 12px 16px;
    color: ${theme.palette.text.primary};
    background-color: ${theme.palette.background.paper};

    &:focus {
      outline: none;
    }

    ${border &&
    css`
      border: 1px solid ${theme.palette.divider};
    `}

    ${error &&
    css`
      border: 1px solid ${theme.palette.error.main};
    `}
  `,
);

export const Error = styled.p(
  ({ theme }) => css`
    margin: 5px 0 0;
    font-size: 12px;
    color: ${theme.palette.error.dark};
  `,
);
