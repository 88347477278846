import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Breakpoint } from '../../enums/breakpoint';

export const Wrapper = styled(Link)(
  ({ theme }) => css`
    display: block;
    background-color: ${theme.palette.background.paper};
    margin-bottom: 12px;

    ${theme.breakpoints.up(Breakpoint.Small)} {
      display: flex;
      align-items: center;
      padding: 12px;
    }

    :last-child {
      margin-bottom: 12px;
    }

    :hover {
      color: ${theme.palette.text.primary};
    }
  `,
);

export const PictureWrapper = styled.div(
  ({ theme }) => css`
    position: relative;
    z-index: ${theme.zIndex.drawer};
    padding-inline-start: 12px;
    padding-top: 4px;
    padding-bottom: 4px 0 4px 12px;

    &:after {
      content: '';
      display: block;
      background-color: ${theme.palette.secondary.main};
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 50%;
    }

    ${theme.breakpoints.up(Breakpoint.Small)} {
      padding: 0;

      &:after {
        display: none;
      }
    }
  `,
);

export const Picture = styled.div(
  ({ theme }) => css`
    display: inline-flex;
    position: relative;
    z-index: ${theme.zIndex.drawer};
    height: 80px;
    width: 80px;
    border-radius: 50%;
    background-color: ${theme.palette.secondary.main};
    border: 2px solid ${theme.palette.background.default};
    justify-content: center;
    align-items: center;

    ${theme.breakpoints.up(Breakpoint.Small)} {
      height: 100px;
      width: 100px;
    }
  `,
);

export const Details = styled.div(
  ({ theme }) => css`
    flex: 1;
    overflow: hidden;
    padding-inline-start: 8px;
    padding-inline-end: 8px;

    ${theme.breakpoints.up(Breakpoint.Small)} {
      padding-inline-start: 12px;
      padding-inline-end: 0;
    }
  `,
);
