import { FC } from 'react';

import { Modal } from '../modal';
import { Container } from '../container';
import {
  Content,
  DeleteButton,
  Footer,
  SaveButton,
  CancelButton,
} from './profile-modal.styles';

interface IProps {
  children: React.ReactNode;
  onCancel: () => void;
  onSave: () => void;
  onDelete?: () => void;
}

export const ProfileModal: FC<IProps> = ({
  children,
  onCancel,
  onDelete,
  onSave,
}) => {
  return (
    <Modal onClose={onCancel}>
      <Container
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Content>{children}</Content>
      </Container>
      <Footer>
        <Container
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {onDelete && (
            <DeleteButton variant="outlined" onClick={onDelete}>
              Delete
            </DeleteButton>
          )}
          <CancelButton variant="outlined" onClick={onCancel}>
            Cancel
          </CancelButton>
          <SaveButton variant="contained" onClick={onSave}>
            Save
          </SaveButton>
        </Container>
      </Footer>
    </Modal>
  );
};
