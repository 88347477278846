import styled, { css } from 'styled-components';

import { Breakpoint } from '../../enums/breakpoint';
import { Button } from '../../components/form-elements/button';

export const Wrapper = styled.div(
  ({ theme }) => css`
    margin: 16px auto;

    ${theme.breakpoints.up(Breakpoint.Large)} {
      display: flex;
    }
  `,
);

export const Section = styled.div(() => css``);

export const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`;

export const SectionContent = styled.div(
  () => css`
    margin-bottom: 12px;
  `,
);

export const History = styled.div(
  ({ theme }) => css`
    ${theme.breakpoints.up(Breakpoint.Large)} {
      padding-inline-start: 16px;
      flex: 1;
      border-left: 1px solid ${theme.palette.divider};
    }
  `,
);

export const StartAddingButton = styled(Button)(
  ({ theme }) => css`
    width: 100%;

    ${theme.breakpoints.up(Breakpoint.Medium)} {
      width: 100px;
    }
  `,
);

export const PortfolioWrapper = styled.div(
  ({ theme }) => css`
    ${theme.breakpoints.up(Breakpoint.Large)} {
      width: 40%;
      position: relative;
      padding-inline-end: 16px;
    }
  `,
);

export const PortfolioContent = styled.div(
  ({ theme }) => css`
    ${theme.breakpoints.up(Breakpoint.Large)} {
      position: sticky;
      top: 16px;
    }
  `,
);
