import { FC } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import * as yup from 'yup';

import { Job } from '../../../types/job';
import { EmploymentType } from '../../../enums/employment-type';
import { WorkplaceType } from '../../../enums/workplace-type';
import { jobSchema } from '../../../schemas/job.schema';
import {
  MAX_PREFERRED_QUALIFICATIONS_NUMBER,
  MIN_REQUIRED_QUALIFICATIONS_NUMBER,
  MAX_REQUIRED_QUALIFICATIONS_NUMBER,
  MIN_RESPONSIBILITIES_NUMBER,
  MAX_RESPONSIBILITIES_NUMBER,
  MIN_BENEFITS_NUMBER,
  MAX_BENEFITS_NUMBER,
} from '../../../constants/job';
import { createJob, deleteJob, updateJob } from '../../../api/job';
import { Container } from '../../../components/container';
import { TextInput } from '../../../components/form-elements/text-input';
import { Button } from '../../../components/form-elements/button';
import { Textarea } from '../../../components/form-elements/textarea';
import { ProfileModal } from '../../../components/profile-modal';
import {
  Content,
  ContentRow,
  LeftContent,
  RightContent,
} from '../../../components/profile-modal/profile-modal.styles';
import { Select } from '../../../components/form-elements/select';
import { Trash } from '../../../components/icons/common/trash';
import { Add } from '../../../components/icons/common/add';
import { Tags } from '../../../components/tags';
import { DatePicker } from '../../../components/form-elements/datepicker';
import { useNavigate } from 'react-router-dom';
import { Route } from '../../../enums/route';

interface IProps {
  job: Omit<Job, 'id'> & { id?: string };
  onCancel: () => void;
  onSave: () => void;
}

export const JobForm: FC<IProps> = ({ job, onCancel, onSave }) => {
  const navigate = useNavigate();
  const { t } = useTranslation('job');

  const { mutate: create } = useMutation(
    async (data: Omit<Job, 'id' | 'user'>) => {
      const resp = await createJob(data);
      navigate(`${Route.Jobs}/${resp.id}`);
    },
  );

  const { mutate: update } = useMutation(async (data: Job) => {
    await updateJob(data);
    onSave();
  });

  const { mutate: del } = useMutation(async (jobId: string) => {
    await deleteJob(jobId);
    navigate(Route.Jobs);
  });

  const { control, reset, formState, handleSubmit, setValue, getValues } =
    useForm<yup.InferType<typeof jobSchema>>({
      mode: 'onChange',
      resolver: yupResolver(jobSchema),
      defaultValues: job,
    });

  const benefits = useFieldArray({
    control,
    name: 'benefits',
  });

  const responsibilities = useFieldArray({
    control,
    name: 'responsibilities',
  });

  const requiredQualifications = useFieldArray({
    control,
    name: 'qualifications.required',
  });

  const preferredQualifications = useFieldArray({
    control,
    name: 'qualifications.preferred',
  });

  return (
    <ProfileModal
      onCancel={() => {
        reset();
        onCancel();
      }}
      onSave={handleSubmit((data) => {
        if (data.id === undefined) {
          create(data);
        } else {
          update(data as Job);
        }
      })}
      onDelete={
        job.id
          ? () => {
              const { id } = getValues();
              if (id) {
                del(id);
              }
            }
          : undefined
      }
    >
      <Container
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Content>
          <ContentRow>
            <h4>{t('job-details')}</h4>
          </ContentRow>
          <ContentRow>
            <LeftContent>
              <h6>{t('position')}</h6>
              <Controller
                name="position"
                control={control}
                render={({ field }) => {
                  return (
                    <TextInput
                      {...field}
                      placeholder={t('position')}
                      error={formState.errors.position?.message}
                    />
                  );
                }}
              />
              <h6>{t('organization')}</h6>
              <Controller
                name="organization"
                control={control}
                render={({ field }) => {
                  return (
                    <TextInput
                      {...field}
                      placeholder={t('organization')}
                      error={formState.errors.organization?.message}
                    />
                  );
                }}
              />
              <h6>{t('employment-type')}</h6>
              <Controller
                name="employmentType"
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      onChange={(e) => {
                        field.onChange(e.target.value as EmploymentType);
                      }}
                      value={field.value}
                      placeholder={t('employment-type')}
                      error={formState.errors.employmentType?.message}
                    >
                      {Object.values(EmploymentType).map((employmentType) => (
                        <option key={employmentType} value={employmentType}>
                          {employmentType}
                        </option>
                      ))}
                    </Select>
                  );
                }}
              />
              <h6>{t('workplace-type')}</h6>
              <Controller
                name="workplaceType"
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      onChange={(e) => {
                        field.onChange(e.target.value as WorkplaceType);
                      }}
                      value={field.value}
                      placeholder={t('workplace-type')}
                      error={formState.errors.workplaceType?.message}
                    >
                      {Object.values(WorkplaceType).map((workplaceType) => (
                        <option key={workplaceType} value={workplaceType}>
                          {workplaceType}
                        </option>
                      ))}
                    </Select>
                  );
                }}
              />
              <h6>{t('translation:location')}</h6>
              <div
                style={{
                  display: 'flex',
                  gap: '12px',
                }}
              >
                <div style={{ flex: 1 }}>
                  <Controller
                    name="location.city"
                    control={control}
                    render={({ field }) => {
                      return (
                        <TextInput
                          {...field}
                          placeholder={t('translation:city')}
                          error={formState.errors.location?.city?.message}
                        />
                      );
                    }}
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <Controller
                    name="location.country"
                    control={control}
                    render={({ field }) => {
                      return (
                        <TextInput
                          {...field}
                          placeholder={t('translation:country')}
                          error={formState.errors.location?.country?.message}
                        />
                      );
                    }}
                  />
                </div>
              </div>
              <h6>{t('benefits')}</h6>
              {benefits.fields.map((field, index) => {
                return (
                  <div
                    key={field.id}
                    style={{
                      display: 'flex',
                      gap: '12px',
                      alignItems: 'start',
                      marginBottom: '12px',
                    }}
                  >
                    <Controller
                      name={`benefits.${index}`}
                      control={control}
                      render={({ field }) => {
                        return (
                          <TextInput
                            onChange={(e) => {
                              field.onChange(
                                {
                                  benefit: e.target.value,
                                },
                                {
                                  shouldValidate: true,
                                },
                              );
                            }}
                            value={field.value.benefit}
                            placeholder={t('benefit-details')}
                            error={
                              formState.errors.benefits?.[index]?.benefit
                                ?.message
                            }
                            style={{
                              marginBottom: 0,
                              flex: 1,
                            }}
                          />
                        );
                      }}
                    />
                    <Button
                      style={{
                        width: '50px',
                      }}
                      variant="outlined"
                      onClick={() => benefits.remove(index)}
                      disabled={benefits.fields.length <= MIN_BENEFITS_NUMBER}
                    >
                      <Trash />
                    </Button>
                  </div>
                );
              })}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginBottom: '12px',
                }}
              >
                <Button
                  variant="outlined"
                  style={{
                    width: '50px',
                  }}
                  onClick={() => {
                    benefits.append({
                      benefit: '',
                    });
                  }}
                  disabled={benefits.fields.length + 1 > MAX_BENEFITS_NUMBER}
                >
                  <Add />
                </Button>
              </div>
              <div>
                <h6>{t('deadline')}</h6>
                <Controller
                  name="deadline"
                  control={control}
                  render={({ field }) => {
                    return (
                      <DatePicker
                        {...field}
                        placeholder={t('deadline')}
                        error={formState.errors.deadline?.message}
                      />
                    );
                  }}
                />
              </div>
            </LeftContent>
            <RightContent>
              <h6>{t('description')}</h6>
              <Controller
                name="description"
                control={control}
                render={({ field }) => {
                  return (
                    <Textarea
                      {...field}
                      placeholder={t('description')}
                      style={{ resize: 'vertical' }}
                      error={formState.errors.description?.message}
                    />
                  );
                }}
              />
              <h6>{t('responsibilities')}</h6>
              {responsibilities.fields.map((field, index) => {
                return (
                  <div
                    key={field.id}
                    style={{
                      display: 'flex',
                      gap: '12px',
                      alignItems: 'start',
                      marginBottom: '12px',
                    }}
                  >
                    <Controller
                      name={`responsibilities.${index}`}
                      control={control}
                      render={({ field }) => {
                        return (
                          <TextInput
                            onChange={(e) => {
                              field.onChange(
                                {
                                  responsibility: e.target.value,
                                },
                                {
                                  shouldValidate: true,
                                },
                              );
                            }}
                            value={field.value.responsibility}
                            placeholder={t('responsibility-details')}
                            error={
                              formState.errors.responsibilities?.[index]
                                ?.responsibility?.message
                            }
                            style={{
                              marginBottom: 0,
                              flex: 1,
                            }}
                          />
                        );
                      }}
                    />
                    <Button
                      style={{
                        width: '50px',
                      }}
                      variant="outlined"
                      onClick={() => responsibilities.remove(index)}
                      disabled={
                        responsibilities.fields.length <=
                        MIN_RESPONSIBILITIES_NUMBER
                      }
                    >
                      <Trash />
                    </Button>
                  </div>
                );
              })}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginBottom: '12px',
                }}
              >
                <Button
                  variant="outlined"
                  style={{
                    width: '50px',
                  }}
                  onClick={() => {
                    responsibilities.append({
                      responsibility: '',
                    });
                  }}
                  disabled={
                    responsibilities.fields.length + 1 >
                    MAX_RESPONSIBILITIES_NUMBER
                  }
                >
                  <Add />
                </Button>
              </div>
              <h6>{t('translation:skills.title')}</h6>
              <Controller
                name="skills"
                control={control}
                render={({ field }) => {
                  return (
                    <Tags
                      placeholder={t(`translation:skills.title`)}
                      tags={field.value}
                      error={formState.errors.skills?.message}
                      onChange={(tags) => {
                        setValue('skills', tags, { shouldValidate: true });
                      }}
                    />
                  );
                }}
              />
              <h6>{t('qualification.required')}</h6>
              {requiredQualifications.fields.map((field, index) => {
                return (
                  <div
                    key={field.id}
                    style={{
                      display: 'flex',
                      gap: '12px',
                      alignItems: 'start',
                      marginBottom: '12px',
                    }}
                  >
                    <Controller
                      name={`qualifications.required.${index}`}
                      control={control}
                      render={({ field }) => {
                        return (
                          <TextInput
                            onChange={(e) => {
                              field.onChange(
                                {
                                  qualification: e.target.value,
                                },
                                {
                                  shouldValidate: true,
                                },
                              );
                            }}
                            value={field.value.qualification}
                            placeholder={t('qualification.details')}
                            error={
                              formState.errors.qualifications?.required?.[index]
                                ?.qualification?.message
                            }
                            style={{
                              marginBottom: 0,
                              flex: 1,
                            }}
                          />
                        );
                      }}
                    />
                    <Button
                      style={{
                        width: '50px',
                      }}
                      variant="outlined"
                      onClick={() => requiredQualifications.remove(index)}
                      disabled={
                        requiredQualifications.fields.length <=
                        MIN_REQUIRED_QUALIFICATIONS_NUMBER
                      }
                    >
                      <Trash />
                    </Button>
                  </div>
                );
              })}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginBottom: '12px',
                }}
              >
                <Button
                  variant="outlined"
                  style={{
                    width: '50px',
                  }}
                  disabled={
                    requiredQualifications.fields.length + 1 >
                    MAX_REQUIRED_QUALIFICATIONS_NUMBER
                  }
                  onClick={() => {
                    requiredQualifications.append({
                      qualification: '',
                    });
                  }}
                >
                  <Add />
                </Button>
              </div>
              <h6>{t('qualification.preferred')}</h6>
              {preferredQualifications.fields.map((field, index) => {
                return (
                  <div
                    key={field.id}
                    style={{
                      display: 'flex',
                      gap: '12px',
                      alignItems: 'start',
                      marginBottom: '12px',
                    }}
                  >
                    <Controller
                      name={`qualifications.preferred.${index}`}
                      control={control}
                      render={({ field }) => {
                        return (
                          <TextInput
                            onChange={(e) => {
                              field.onChange(
                                {
                                  qualification: e.target.value,
                                },
                                {
                                  shouldValidate: true,
                                },
                              );
                            }}
                            value={field.value.qualification}
                            placeholder={t('qualification.details')}
                            error={
                              formState.errors.qualifications?.preferred?.[
                                index
                              ]?.qualification?.message
                            }
                            style={{
                              marginBottom: 0,
                              flex: 1,
                            }}
                          />
                        );
                      }}
                    />
                    <Button
                      style={{
                        width: '50px',
                      }}
                      variant="outlined"
                      onClick={() => preferredQualifications.remove(index)}
                    >
                      <Trash />
                    </Button>
                  </div>
                );
              })}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginBottom: '12px',
                }}
              >
                <Button
                  variant="outlined"
                  style={{
                    width: '50px',
                  }}
                  disabled={
                    preferredQualifications.fields.length + 1 >
                    MAX_PREFERRED_QUALIFICATIONS_NUMBER
                  }
                  onClick={() => {
                    preferredQualifications.append({
                      qualification: '',
                    });
                  }}
                >
                  <Add />
                </Button>
              </div>
            </RightContent>
          </ContentRow>
        </Content>
      </Container>
    </ProfileModal>
  );
};
