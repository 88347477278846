import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

type Variant = 'text' | 'contained' | 'outlined';

interface INavbarLinkProps {
  variant?: Variant;
}

export const NavbarLink = styled(NavLink)<INavbarLinkProps>(
  ({ theme, variant = 'text' }) => css`
    display: inline-flex;
    align-items: center;
    padding: 6px 12px;
    border-bottom: 1px solid transparent;

    border: 1px solid transparent;

    &.active {
      border-bottom: 2px solid ${theme.palette.secondary.main};
    }

    ${variant === 'text' &&
    css`
      color: ${theme.palette.text.primary};
      background-color: ${theme.palette.background.default};
      height: 63px;
    `}

    ${variant === 'contained' &&
    css`
      height: 40px;
      border-radius: 4px;
      color: ${theme.palette.primary.dark};
      background-color: ${theme.palette.secondary.main};

      &:hover {
        color: ${theme.palette.primary.dark};
        background-color: ${theme.palette.secondary.dark};
      }
    `}
  `,
);

export const NavbarWrapper = styled.nav(() => css``);
