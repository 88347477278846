export enum Route {
  Home = '/',
  Settings = '/settings',
  Talents = '/talents',
  Jobs = '/jobs',
  Portfolios = '/portfolios',
  SignUp = '/sign-up',
  SignIn = '/sign-in',
  ForgotPassword = '/forgot-password',
  ResetPassword = '/reset-password',
  EmailVerification = '/email-verification',
  PrivacyPolicy = '/privacy-policy',
  TermsOfUse = '/terms-of-use',
  CookiePolicy = '/cookie-policy',
}
