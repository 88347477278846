import {
  ChangeEvent,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import {
  Button,
  DatePickerContainer,
  Calendar,
  CalendarHeader,
  CalendarBody,
  Day,
  Select,
  WeekDay,
} from './datepicker.styles';
import { TextInput } from '../text-input';
import { useOutsideClick } from '../../../hooks/outside-click.hook';
import { ArrowLeft } from '../../icons/common/arrow-left';
import { ArrowRight } from '../../icons/common/arrow-right';

dayjs.extend(customParseFormat);

// Helper function to generate calendar days
const generateCalendar = (year: number, month: number) => {
  const firstDay = dayjs().year(year).month(month).date(1).day();
  const daysInMonth = dayjs().year(year).month(month).daysInMonth();

  const days = [];
  for (let i = 0; i < firstDay; i++) {
    days.push(null);
  }
  for (let i = 1; i <= daysInMonth; i++) {
    days.push(i);
  }
  return days;
};

interface IProps {
  value?: string;
  format?: string;
  placeholder?: string;
  onChange: (date: string) => void;
  error?: string;
}

// DatePicker component
export const DatePicker = forwardRef<HTMLDivElement, IProps>(
  (
    {
      value,
      format = process.env.REACT_APP_DATE_FORMAT,
      placeholder,
      onChange,
      error = '',
    },
    ref,
  ) => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const calendarRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const [selectedDate, setSelectedDate] = useState<string>(
      !!value ? dayjs(value, format).format(format) : '',
    );

    const [currentDate, setCurrentDate] = useState(
      !!selectedDate ? selectedDate : dayjs().format(format),
    );

    const [showCalendar, setShowCalendar] = useState(false);
    const [calendarPosition, setCalendarPosition] = useState<
      | {
          top: number;
          left: number;
        }
      | {
          top: number;
          right: number;
        }
    >({ top: 50, left: 0 });

    const adjustCalendarPosition = useCallback(() => {
      if (showCalendar && inputRef.current && calendarRef.current) {
        const inputRect = inputRef.current.getBoundingClientRect();
        const calendarRect = calendarRef.current.getBoundingClientRect();

        let top = 50;
        let horizontalPosition:
          | {
              left: number;
              right?: never;
            }
          | {
              left?: never;
              right: number;
            };

        if (inputRect.right - calendarRect.width > 0) {
          horizontalPosition = {
            right: 0,
          };
        } else {
          horizontalPosition = {
            left: 0,
          };
        }

        setCalendarPosition({ top, ...horizontalPosition });
      }
    }, [showCalendar]);

    useEffect(() => {
      adjustCalendarPosition();
    }, [adjustCalendarPosition]);

    useEffect(() => {
      window.addEventListener('resize', adjustCalendarPosition);
      window.addEventListener('scroll', adjustCalendarPosition);

      return () => {
        window.removeEventListener('resize', adjustCalendarPosition);
        window.removeEventListener('scroll', adjustCalendarPosition);
      };
    }, [adjustCalendarPosition]);

    useImperativeHandle(ref, () => {
      return wrapperRef.current as HTMLDivElement;
    }, []);

    useOutsideClick(
      wrapperRef,
      () => {
        setShowCalendar(false);
      },
      { enabled: showCalendar },
    );

    const year = dayjs(currentDate, format).year();
    const month = dayjs(currentDate, format).month();
    const days = generateCalendar(year, month);

    const handleDayClick = (day: number) => {
      const newDate = dayjs().year(year).month(month).date(day).format(format);
      setSelectedDate(newDate);
      setShowCalendar(false);
      onChange(newDate);
    };

    const handlePrevMonth = () => {
      setCurrentDate(
        dayjs().year(year).month(month).subtract(1, 'month').format(format),
      );
    };

    const handleNextMonth = () => {
      setCurrentDate(
        dayjs().year(year).month(month).add(1, 'month').format(format),
      );
    };

    const handleMonthChange = (event: ChangeEvent<HTMLSelectElement>) => {
      setCurrentDate(
        dayjs().year(year).month(parseInt(event.target.value)).format(format),
      );
    };

    const handleYearChange = (event: ChangeEvent<HTMLSelectElement>) => {
      setCurrentDate(
        dayjs().year(parseInt(event.target.value)).month(month).format(format),
      );
    };

    const getYears = () => {
      const years = [];
      for (let i = year - 50; i <= year + 50; i++) {
        years.push(i);
      }
      return years;
    };

    return (
      <DatePickerContainer ref={wrapperRef}>
        <TextInput
          ref={inputRef}
          placeholder={placeholder}
          value={selectedDate ? selectedDate : ''}
          readOnly
          error={error}
          onClick={() => setShowCalendar(!showCalendar)}
        />
        {showCalendar && (
          <Calendar ref={calendarRef} style={calendarPosition}>
            <CalendarHeader>
              <Button onClick={handlePrevMonth}>
                <ArrowLeft width={10} />
              </Button>
              <Select value={month} onChange={handleMonthChange}>
                {Array.from({ length: 12 }, (_, i) => i).map((monthIndex) => {
                  return (
                    <option key={monthIndex} value={monthIndex}>
                      {dayjs().month(monthIndex).format('MMMM')}
                    </option>
                  );
                })}
              </Select>
              <Select value={year} onChange={handleYearChange}>
                {getYears().map((yearOption) => (
                  <option key={yearOption} value={yearOption}>
                    {yearOption}
                  </option>
                ))}
              </Select>
              <Button onClick={handleNextMonth}>
                <ArrowRight width={10} />
              </Button>
            </CalendarHeader>
            <CalendarBody>
              {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((day, index) => (
                <WeekDay key={`${day}-${index}`}>{day}</WeekDay>
              ))}
              {days.map((day, index) => (
                <Day
                  key={`day-${index}`}
                  empty={!day}
                  selected={
                    // !!selectedDate && day === new Date(selectedDate).getDate()
                    !!selectedDate && day === dayjs(selectedDate, format).date()
                  }
                  onClick={() => day && handleDayClick(day)}
                >
                  {day}
                </Day>
              ))}
            </CalendarBody>
          </Calendar>
        )}
      </DatePickerContainer>
    );
  },
);
