import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { ThemeStyle } from '../enums/theme-style';

export const ThemeStyleContext = createContext<
  [ThemeStyle, Dispatch<SetStateAction<ThemeStyle>>] | undefined
>(undefined);

export function useThemeStyleContext(): [
  ThemeStyle,
  Dispatch<SetStateAction<ThemeStyle>>,
] {
  const context = useContext(ThemeStyleContext);

  if (context === undefined) {
    throw new Error('ThemeStyleContext.Provider is not used.');
  }

  return context;
}
