import { FC, useMemo } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import * as yup from 'yup';

import { portfolioSchema } from '../../../schemas/portfolio.schema';
import { Portfolio } from '../../../types/portfolio';
import { createPortfolio, updatePortfolio } from '../../../api/portfolio';
import { getLanguages } from '../../../api/language';
import { ProfileModal } from '../../../components/profile-modal/profile-modal';
import {
  ContentRow,
  LeftContent,
  RightContent,
} from '../../../components/profile-modal/profile-modal.styles';
import { TextInput } from '../../../components/form-elements/text-input';
import { Textarea } from '../../../components/form-elements/textarea';
import { Tags } from '../../../components/tags';

import { Dropdown } from '../../../components/form-elements/dropdown';
import { Button } from '../../../components/form-elements/button';
import { Trash } from '../../../components/icons/common/trash';
import { Add } from '../../../components/icons/common/add';
import { EMPTY_PORTFOLIO } from '../../../constants/portfolio';

interface IProps {
  portfolio?: Portfolio;
  onCancel: () => void;
  onSave: () => void;
}

export const AboutForm: FC<IProps> = ({ portfolio, onCancel, onSave }) => {
  const { t } = useTranslation(['profile', 'translation']);

  const { mutate: create } = useMutation(
    async (
      data: Omit<Portfolio, 'id' | 'user' | 'experiences' | 'educations'>,
    ) => {
      await createPortfolio(data);

      onSave();
    },
  );

  const { mutate: update } = useMutation(
    async (data: Omit<Portfolio, 'user' | 'experiences' | 'educations'>) => {
      await updatePortfolio(data);
      onSave();
    },
  );

  const { data, isLoading } = useQuery(['languages'], getLanguages, {
    onError: console.error,
    retry: false,
  });

  const languages = useMemo(() => {
    return data?.languages.map(({ language }) => {
      return {
        value: language,
        label: language,
      };
    });
  }, [data]);

  const languageProviciencyLevels = useMemo(() => {
    return data?.proficiencyLevels.map(({ level }) => {
      return {
        label: t(`translation:language-proficiencies.${level}`),
        value: level,
      };
    });
  }, [data?.proficiencyLevels, t]);

  const { control, reset, setValue, formState, handleSubmit } = useForm<
    yup.InferType<typeof portfolioSchema>
  >({
    mode: 'onChange',
    resolver: yupResolver(portfolioSchema),
    defaultValues: portfolio ?? EMPTY_PORTFOLIO,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'languages',
  });

  if (isLoading) {
    return null;
  }

  return (
    <ProfileModal
      onCancel={() => {
        reset();
        onCancel();
      }}
      onSave={handleSubmit((data) => {
        if (data.id === undefined) {
          create(data);
        } else {
          update(data as Portfolio);
        }
      })}
    >
      <ContentRow>
        <LeftContent>
          <h4>{t('about.title')}</h4>
        </LeftContent>
        <RightContent>
          <h6>{t('occupation.title')}</h6>
          <Controller
            name="occupation"
            control={control}
            render={({ field }) => {
              return (
                <TextInput
                  {...field}
                  placeholder={t('occupation.title')}
                  error={formState.errors.occupation?.message}
                />
              );
            }}
          />

          <h6>{t('location.title')}</h6>
          <div
            style={{
              display: 'flex',
              gap: '12px',
            }}
          >
            <div style={{ flex: 1 }}>
              <Controller
                name="location.city"
                control={control}
                render={({ field }) => {
                  return (
                    <TextInput
                      {...field}
                      placeholder={t('translation:city')}
                      error={formState.errors.location?.city?.message}
                    />
                  );
                }}
              />
            </div>
            <div style={{ flex: 1 }}>
              <Controller
                name="location.country"
                control={control}
                render={({ field }) => {
                  return (
                    <TextInput
                      {...field}
                      placeholder={t('translation:country')}
                      error={formState.errors.location?.country?.message}
                    />
                  );
                }}
              />
            </div>
          </div>

          <h6>{t('headline.title')}</h6>
          <Controller
            name="headline"
            control={control}
            render={({ field }) => {
              return (
                <Textarea
                  {...field}
                  placeholder="Tell about yourself"
                  style={{ resize: 'none' }}
                  error={formState.errors.headline?.message}
                />
              );
            }}
          />
          <h6>{t('translation:skills.title')}</h6>
          <Controller
            name="skills"
            control={control}
            render={({ field }) => {
              return (
                <Tags
                  placeholder={t(`translation:skills.title`)}
                  tags={field.value}
                  error={formState.errors.skills?.message}
                  onChange={(tags) => {
                    setValue('skills', tags, { shouldValidate: true });
                  }}
                />
              );
            }}
          />

          <h6>{t(`languages.title`)}</h6>
          {fields.map((_, index) => {
            return (
              <div key={index} style={{ display: 'flex', gap: '12px' }}>
                <div style={{ flex: 1 }}>
                  <Controller
                    name={`languages.${index}.language`}
                    control={control}
                    render={({ field }) => {
                      return (
                        <Dropdown
                          placeholder="Language"
                          value={field.value}
                          error={
                            formState.errors.languages?.[index]?.language
                              ?.message
                          }
                          options={languages}
                          onChange={(option) => {
                            field.onChange(option.value, {
                              shouldValidate: true,
                            });
                          }}
                        />
                      );
                    }}
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <Controller
                    name={`languages.${index}.proficiency`}
                    control={control}
                    render={({ field }) => {
                      return (
                        <Dropdown
                          placeholder="Proficiency"
                          value={
                            field.value
                              ? t(
                                  `translation:language-proficiencies.${field.value}`,
                                )
                              : ''
                          }
                          error={
                            formState.errors.languages?.[index]?.proficiency
                              ?.message
                          }
                          options={languageProviciencyLevels}
                          onChange={(option) => {
                            field.onChange(option.value, {
                              shouldValidate: true,
                            });
                          }}
                        />
                      );
                    }}
                  />
                </div>
                <div>
                  <Button
                    style={{
                      width: '50px',
                    }}
                    variant="outlined"
                    onClick={() => remove(index)}
                  >
                    <Trash />
                  </Button>
                </div>
              </div>
            );
          })}
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              variant="outlined"
              style={{
                width: '50px',
              }}
              onClick={() => {
                append({
                  language: '',
                  proficiency: '',
                });
              }}
            >
              <Add />
            </Button>
          </div>
        </RightContent>
      </ContentRow>
    </ProfileModal>
  );
};
