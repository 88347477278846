import { ForwardedRef, forwardRef, InputHTMLAttributes, FC } from 'react';
import { Wrapper, TextareaInput, TextareaError } from './textarea.styles';

export interface IProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  border?: boolean;
  error?: string;
}

export const Textarea: FC<IProps> = forwardRef(
  (props, ref: ForwardedRef<HTMLTextAreaElement>) => {
    return (
      <Wrapper>
        <TextareaInput
          {...props}
          ref={ref}
          border={!!props.border}
          error={!!props.error}
        />
        {!!props.error && <TextareaError>{props.error}</TextareaError>}
      </Wrapper>
    );
  },
);
