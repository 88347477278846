import styled, { css } from 'styled-components';
import { Breakpoint } from '../../enums/breakpoint';

export const Wrapper = styled.div(
  ({ theme }) => css`
    margin: 16px auto;

    ${theme.breakpoints.up(Breakpoint.Medium)} {
      display: flex;
      gap: 12px;
    }
  `,
);

export const Widget = {
  Wrapper: styled.div(
    ({ theme }) => css`
      margin-bottom: 16px;
      background-color: ${theme.palette.background.paper};

      ${theme.breakpoints.up(Breakpoint.Medium)} {
        flex: 1;
      }
    `,
  ),
  Header: styled.div(
    ({ theme }) => css`
      padding: 16px;
      border-bottom: 1px solid ${theme.palette.divider};
    `,
  ),
  Body: styled.div(
    ({ theme }) => css`
      height: 300px;
      display: flex;
      justify-content: center;
      padding: 16px 16px 24px;

      ${theme.breakpoints.up(Breakpoint.Medium)} {
        height: 350px;
      }
    `,
  ),
};
