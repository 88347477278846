export const Sun = ({ color = 'currentColor', width = 16 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      viewBox="0 0 16 16"
      fill={color}
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="8" cy="8" r="3.33"></circle>
      <line x1="8" y1="0.67" x2="8" y2="2"></line>
      <line x1="8" y1="14" x2="8" y2="15.33"></line>
      <line x1="2.81" y1="2.81" x2="3.75" y2="3.75"></line>
      <line x1="12.25" y1="12.25" x2="13.19" y2="13.19"></line>
      <line x1="0.67" y1="8" x2="2" y2="8"></line>
      <line x1="14" y1="8" x2="15.33" y2="8"></line>
      <line x1="2.81" y1="13.19" x2="3.75" y2="12.25"></line>
      <line x1="12.25" y1="3.75" x2="13.19" y2="2.81"></line>
    </svg>
  );
};
