import styled, { css } from 'styled-components';
import { Breakpoint } from '../../enums/breakpoint';

export const Wrapper = styled.div(
  ({ theme }) => css`
    margin: 16px auto;

    ${theme.breakpoints.up(Breakpoint.Large)} {
      display: flex;
    }
  `,
);

export const LeftContent = styled.div(
  ({ theme }) => css`
    ${theme.breakpoints.up(Breakpoint.Large)} {
      padding-inline-end: 16px;
      width: 40%;
    }
  `,
);

export const RightContent = styled.div(
  ({ theme }) => css`
    ${theme.breakpoints.up(Breakpoint.Large)} {
      border-left: 1px solid ${theme.palette.divider};
      padding-inline-start: 16px;
      width: 60%;
    }
  `,
);
