export enum ErrorCode {
  // General error codes
  SomethingWentWrong = 1000,
  BadRequest = 1001,

  // User error codes
  UserNotFound = 2000,
  EmailUsed = 2001,
  UserNotAuthorized = 2002,
  UserNotAuthenticated = 2003,
  UserPlatformExists = 2004,
  IncorrectCredentials = 2005,
  PortfolioNotFound = 2006,
  ExperienceNotFound = 2007,
  EducationNotFound = 2008,
  JobNotFound = 2009,

  // Validation error codes
  ValidationError = 3000,
  AccessTokenExpired = 3001,
  RefreshTokenExpired = 3002,
  TokenInvalid = 3003,
  EmailVerificationTokenExpired = 3004,
}
