import styled, { css } from 'styled-components';
import { Breakpoint } from '../../enums/breakpoint';
import { Link } from 'react-router-dom';

export const Wrapper = styled.aside<{ open: boolean }>(
  ({ theme, open }) => css`
    display: none;
    position: relative;
    z-index: ${theme.zIndex.sidebar};
    background-color: ${theme.palette.common.overlay};

    ${open &&
    css`
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    `}

    ${theme.breakpoints.up(Breakpoint.Medium)} {
      position: static;
      display: block;
    }

    @media print {
      display: none;
    }
  `,
);

export const Nav = styled.nav(
  ({ theme }) => css`
    width: 200px;
    height: 100%;
    background-color: ${theme.palette.background.paper};
    border-right: 1px solid ${theme.palette.divider};
  `,
);

export const NavHeader = styled.div(
  ({ theme }) => css`
    display: flex;
    height: 64px;
    align-items: center;
    border-bottom: 1px solid ${theme.palette.divider};
    padding: 0 8px;

    ${theme.breakpoints.up(Breakpoint.Small)} {
      padding: 0 16px;
    }
  `,
);

export const NavContent = styled.div(
  ({ theme }) => css`
    padding: 8px;

    ${theme.breakpoints.up(Breakpoint.Small)} {
      padding: 8px 16px;
    }
  `,
);

export const NavLinkWrapper = styled(Link)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    padding: 12px 8px;
    cursor: pointer;

    &:hover {
      background-color: ${theme.palette.action.hover};
    }
  `,
);

export const NavItemLabel = styled.span(
  () => css`
    margin-left: 8px;
  `,
);

export const Brand = styled.span(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    color: ${theme.palette.secondary.main};
    font-weight: bold;
    padding: 0 8px;
  `,
);
