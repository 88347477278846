import React from 'react';

interface UserIconProps {
  color?: string;
  width?: number;
}

export const User: React.FC<UserIconProps> = ({
  color = 'currentColor',
  width = 16,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      width={width}
      height={width}
    >
      <circle cx="12" cy="8" r="4" />
      <path d="M4 20v-2c0-2.66 4.67-4 8-4s8 1.34 8 4v2" />
    </svg>
  );
};
