import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';
import { refreshToken } from '../api/auth';
import { ErrorCode } from '../enums/error-code';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

axios.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error: unknown) => {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  async (response) => response,
  async (error: unknown) => {
    if (axios.isAxiosError(error) && isTokenExpired(error)) {
      try {
        const newAccessToken = await refreshAccessToken();
        if (error.config) {
          error.config.headers.Authorization = `Bearer ${newAccessToken}`;
          return axios.request(error.config);
        }
      } catch {
        clearTokens();
      }
    }
    return Promise.reject(error);
  },
);

function isTokenExpired(error: AxiosError): boolean {
  const { response } = error;
  return (
    response !== undefined &&
    response.status === 401 &&
    isArrayOfErrors(response.data) &&
    response.data.code === ErrorCode.UserNotAuthenticated
  );
}

async function refreshAccessToken() {
  const rToken = localStorage.getItem('refreshToken');

  if (!rToken) {
    throw new Error('Refresh token not found');
  }

  const { accessToken, refreshToken: newRefreshToken } =
    await refreshToken(rToken);
  localStorage.setItem('accessToken', accessToken);
  localStorage.setItem('refreshToken', newRefreshToken);
  return accessToken;
}

function clearTokens() {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
}

interface IError {
  code: ErrorCode;
  errors: {
    message: string;
  }[];
}

function isArrayOfErrors(obj: unknown): obj is IError {
  // Check if obj is an array
  if (typeof obj !== 'object' || obj === null || !('errors' in obj)) {
    return false;
  }

  // Check if obj has a code property
  if (!Object(obj).hasOwnProperty('code')) {
    return false;
  }

  // Check if obj.errors is an array
  if (!Array.isArray(obj.errors)) {
    return false;
  }

  // Check if all elements in obj.errors are objects with a message property
  return obj.errors.every((error: unknown) => {
    if (error && typeof error === 'object') {
      return 'message' in error;
    }

    return false;
  });
}
