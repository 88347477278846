import {
  ChangeEvent,
  forwardRef,
  Ref,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Arrow,
  Option,
  Options,
  SelectedValue,
  Wrapper,
} from './dropdown.styles';
import { useOutsideClick } from '../../../hooks/outside-click.hook';
import { TextInput } from '../text-input';

type OptionType<T = any> = {
  value: T;
  label: string;
};

interface IProps {
  options?: OptionType[];
  onChange: (option: OptionType) => void;
  value?: string;
  placeholder?: string;
  allowNew?: boolean;
  error?: string;
}

export const Dropdown = forwardRef<HTMLInputElement, IProps>(
  (
    {
      options,
      placeholder = '',
      onChange,
      value = '',
      allowNew = false,
      error = '',
    }: IProps,
    ref: Ref<HTMLElement>,
  ) => {
    const wrapperRef = useRef<HTMLInputElement>(null);
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState(value);

    const filteredOptions = useMemo(() => {
      if (!options) {
        return [];
      }

      return options.filter((option) =>
        option.label.toLowerCase().includes(searchTerm.toLowerCase()),
      );
    }, [options, searchTerm]);

    useImperativeHandle(ref, () => {
      return wrapperRef.current as HTMLElement;
    }, []);

    useOutsideClick(
      wrapperRef,
      () => {
        setIsOpen(false);
        // setSearchTerm(value);
      },
      { enabled: isOpen },
    );

    return (
      <Wrapper ref={wrapperRef}>
        <>
          <SelectedValue>
            <TextInput
              value={searchTerm}
              type="text"
              autoComplete="off"
              placeholder={placeholder}
              error={error}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setSearchTerm(e.target.value);
              }}
              onClick={() => {
                setIsOpen((isOpen) => !isOpen);
              }}
            />
          </SelectedValue>
          <Arrow open={isOpen} />
        </>
        {isOpen && (
          <Options>
            {allowNew && searchTerm !== '' && (
              <Option
                onClick={() => {
                  onChange({
                    value: searchTerm,
                    label: searchTerm,
                  });
                  setIsOpen((isOpen) => !isOpen);
                }}
              >
                Create "{searchTerm}"
              </Option>
            )}
            {filteredOptions.map((option, index) => {
              return (
                <Option
                  onClick={(e) => {
                    setSearchTerm(option.label);
                    onChange(option);
                    setIsOpen((isOpen) => !isOpen);
                  }}
                  key={`${option.value}-${index}`}
                >
                  {option.label}
                </Option>
              );
            })}
          </Options>
        )}
      </Wrapper>
    );
  },
);
