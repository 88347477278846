import styled, { css } from 'styled-components';

export const Wrapper = styled.footer(
  ({ theme }) => css`
    border-top: 1px solid ${theme.palette.divider};
    display: flex;

    @media print {
      display: none;
    }
  `,
);

export const Company = styled.div`
  width: 40%;
`;

export const Content = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;
  `,
);

export const Logo = styled.a(
  () => css`
    font-weight: bold;
    font-size: 24px;
  `,
);

export const SocialPlatforms = styled.div`
  list-style: none;
  display: flex;
  margin-top: 24px;
`;

export const SocialPlatform = styled.div`
  margin-right: 12px;
`;

export const Copyright = styled.div`
  padding: 8px 0;
  font-weight: bold;
`;

export const LinkList = styled.ul(
  ({ theme }) => css`
    list-style: none;
    display: flex;
    margin: 0 0 16px;
    padding: 0;
  `,
);

export const LinkListItem = styled.li(
  ({ theme }) => css`
    margin-right: 8px;
  `,
);
