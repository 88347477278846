import { FC, KeyboardEvent, useRef } from 'react';

import { TextInput } from '../form-elements/text-input';
import { Add } from '../icons/common/add';
import { TagList } from '../tag-list';
import { AddButton, Wrapper } from './tags.style';

interface IProps {
  tags: string[];
  placeholder?: string;
  onChange?: (tags: string[]) => void;
  error?: string;
}

export const Tags: FC<IProps> = ({ placeholder, tags, onChange, error }) => {
  const addNewTag = (newTag: string) => {
    if (
      newTag !== '' &&
      !tags.find((tag) => tag.toLowerCase() === newTag.toLowerCase())
    ) {
      const newTags = [...tags, newTag];
      onChange?.(newTags);
    }
  };

  const handleInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    const newTag = e.currentTarget.value.trim();
    if (e.key === 'Enter') {
      addNewTag(newTag);
      // reset input value
      e.currentTarget.value = '';
    } else if (e.key === 'Backspace' && newTag === '') {
      const newTags = tags.slice(0, -1);
      onChange?.(newTags);
    }
  };

  const handleAddTagClick = () => {
    const newTag = inputRef.current?.value?.trim();
    if (newTag !== '') {
      addNewTag(newTag || '');
    }
    if (inputRef.current) {
      // reset input value
      inputRef.current.value = '';
      inputRef.current.focus();
    }
  };

  const removeTag = (tagToRemove: string) => {
    const newTags = tags.filter((tag) => tag !== tagToRemove);
    onChange?.(newTags);
  };

  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <Wrapper>
        <TextInput
          type="text"
          onKeyDown={handleInputKeyDown}
          placeholder={placeholder}
          ref={inputRef}
          style={{ flex: 1, marginBottom: 0 }}
          error={error}
        />
        <AddButton
          size="medium"
          variant="outlined"
          style={{}}
          onClick={handleAddTagClick}
        >
          <Add />
        </AddButton>
      </Wrapper>
      <TagList tags={tags} removeTag={removeTag} />
    </>
  );
};
