import styled, { css } from 'styled-components';
import { Button } from '../form-elements/button';

export const DropdownWrapper = styled.div(
  () => css`
    position: relative;
    display: inline-block;
  `,
);

export const DropdownToggle = styled(Button)(() => css``);

export const DropdownMenu = styled.div<{ isOpen?: boolean }>(
  ({ theme, isOpen }) => css`
    visibility: ${isOpen ? 'visible' : 'hidden'};

    min-width: 150px;
    width: auto;
    overflow: auto;

    background: ${theme.palette.background.paper};
    border: 1px solid ${theme.palette.divider};
  `,
);

export const DropdownMenuItem = styled.div(
  ({ theme }) => css`
    color: ${theme.palette.text.primary};
    cursor: pointer;
    display: block;
    padding: 12px 16px;

    &:hover {
      background-color: ${theme.palette.action.hover};
      color: ${theme.palette.secondary.dark};
    }
  `,
);
