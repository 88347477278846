import styled, { css } from 'styled-components';
import { Breakpoint } from '../../enums/breakpoint';

export const Wrapper = styled.div(
  ({ theme }) => css`
    ${theme.breakpoints.up(Breakpoint.Medium)} {
      display: none;
    }
  `,
);
