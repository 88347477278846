import styled, { css } from 'styled-components';
import { Breakpoint } from '../../enums/breakpoint';

interface IProps {
  fluid?: boolean;
}

export const Container = styled.div<IProps>(
  ({ theme, fluid = false }) => css`
    max-width: 1276px;
    width: 100%;
    margin: auto;
    padding: 0 8px;

    ${theme.breakpoints.up(Breakpoint.Small)} {
      padding: 0 16px;
    }

    ${fluid &&
    css`
      max-width: 100%;
    `}
  `,
);
