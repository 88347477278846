import * as yup from 'yup';
import { locationSchema } from './location.schema';
import {
  RESPONSIBILITY_MAX_LENGTH,
  RESPONSIBILITY_MIN_LENGTH,
  MIN_EXPERIENCE_RESPONSIBILITIES_NUMBER,
} from '../constants/experience';

export const experienceSchema = yup.object({
  id: yup.string(),
  organization: yup.string().required(),
  position: yup.string().required(),
  location: locationSchema,
  tenure: yup
    .object({
      from: yup.string().required(),
      to: yup.string(),
    })
    .required(),
  responsibilities: yup
    .array()
    .of(
      yup.object({
        responsibility: yup
          .string()
          .min(RESPONSIBILITY_MIN_LENGTH)
          .max(RESPONSIBILITY_MAX_LENGTH)
          .required(),
      }),
    )
    .min(MIN_EXPERIENCE_RESPONSIBILITIES_NUMBER)
    .required(),
  skills: yup.array().of(yup.string().required()).required(),
});
