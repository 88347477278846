import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link } from 'react-router-dom';

import { forgotPassword } from '../../api/auth';
import { Auth as AuthLayout } from '../../components/layouts/auth';
import { TextInput } from '../../components/form-elements/text-input';
import { Button } from '../../components/form-elements/button';
import { forgotPasswordSchema } from '../../schemas/forgot-password.schema';
import { useMutation } from 'react-query';
import { Alert } from '../../components/alert';

export const ForgotPassword: FC = () => {
  const { t } = useTranslation(['auth', 'translation']);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(forgotPasswordSchema),
    defaultValues: {
      email: '',
    },
  });

  const { mutate, isSuccess } = useMutation(forgotPassword, {
    onError: console.error,
  });

  const onSubmit = (data: { email: string }) => {
    mutate(data.email);
  };

  return (
    <AuthLayout>
      <h5>{t('translation:forgot-password')}</h5>
      <p>{t('password-reset-instructions')}</p>
      <form style={{ marginTop: '20px' }} onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          {...register('email')}
          error={errors?.email?.message}
          border
          placeholder={t('translation:email')}
        />

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '12px',
          }}
        >
          <Link to="/sign-in">{t('translation:sign-in')}</Link>
          <Button type="submit" variant="contained" size="small">
            {t('translation:send')}
          </Button>
        </div>
        {isSuccess && (
          <Alert variant="success">{t('password-reset-success')}</Alert>
        )}
      </form>
    </AuthLayout>
  );
};
