import { Container } from '../../components/container';
import { LeftContent, RightContent, Wrapper } from './settings.styles';
import { UpdatePasswordForm } from './update-password-form';

export const Settings = () => {
  return (
    <Container>
      <Wrapper>
        <LeftContent></LeftContent>
        <RightContent>
          <UpdatePasswordForm />
        </RightContent>
      </Wrapper>
    </Container>
  );
};
