import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Wrapper } from './error.styles';
import { Route } from '../../enums/route';
import { Container } from '../../components/container';

interface IProps {
  statusCode: number;
}

export const Error: FC<IProps> = ({ statusCode }) => {
  const { t } = useTranslation('home');

  return (
    <Container>
      <Wrapper>
        <h1>{statusCode}</h1>
        <div>
          <Link to={Route.Home}>{t('home')}</Link>
        </div>
      </Wrapper>
    </Container>
  );
};
