import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useMutation } from 'react-query';

import { verifyEmail } from '../../api/auth';
import { useUserContext } from '../../contexts/user';
import { Alert } from '../../components/alert';
import { Auth } from '../../components/layouts/auth';

export const EmailVerification = () => {
  const [searchParams] = useSearchParams();
  const [user] = useUserContext();

  const { mutate, isLoading, isSuccess, isError } = useMutation(() =>
    verifyEmail(
      searchParams.get('id') as string,
      searchParams.get('token') as string,
    ),
  );

  useEffect(() => {
    if (user && user.isEmailVerified) {
      return;
    }

    if (searchParams.get('id') && searchParams.get('token')) {
      mutate();
    }
  }, [mutate, user, searchParams]);

  if (isLoading) {
    return <Auth>Loading...</Auth>;
  }

  if (isError) {
    return (
      <Auth>
        <Alert variant="danger">Email verification failure</Alert>
      </Auth>
    );
  }

  if (isSuccess) {
    return (
      <Auth>
        <Alert variant="success">Email verified success</Alert>
      </Auth>
    );
  }

  return (
    <Auth>
      <Alert variant="info">Email verification</Alert>
    </Auth>
  );
};
