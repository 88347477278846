import styled, { css } from 'styled-components';

export const Wrapper = styled.header(
  ({ theme }) => css`
    border-bottom: 1px solid ${theme.palette.divider};
    display: flex;
    height: 64px;

    @media print {
      display: none;
    }
  `,
);

export const Content = styled.div(
  () => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
);
