import { DefaultTheme } from 'styled-components';

import { Breakpoint } from '../enums/breakpoint';
import { ThemeStyle } from '../enums/theme-style';

const paletteLight = {
  mode: ThemeStyle.Light,
  common: {
    black: 'rgba(0, 0, 0, 1)',
    white: 'rgba(255, 255, 255, 1)',
    overlay: 'rgba(0, 0, 0, 0.4)',
  },
  primary: {
    main: 'rgba(255, 255, 255, 1)',
    light: 'rgba(127, 127, 127, 1)',
    dark: 'rgba(0, 0, 0, 1)',
    contrastText: 'rgba(0, 0, 0, 1)',
  },
  secondary: {
    main: 'rgba(252, 213, 53, 1)',
    light: 'rgba(222, 211, 169, 1)',
    dark: 'rgba(240, 185, 11, 1)',
    contrastText: 'rgba(255, 255, 255, 1)',
  },
  success: {
    main: 'rgba(102, 187, 106, 1)',
    light: 'rgba(212, 247, 214, 1)',
    dark: 'rgba(56, 142, 60, 1)',
    contrastText: '',
  },
  error: {
    main: 'rgba(244, 67, 54, 1)',
    light: 'rgba(231, 84, 84, 0.5)',
    dark: 'rgba(211, 47, 47, 1)',
    contrastText: '',
  },
  warning: {
    main: 'rgba(255, 167, 38, 1)',
    light: 'rgba(255, 183, 77, 1)',
    dark: 'rgba(245, 124, 0, 1)',
    contrastText: '',
  },
  info: {
    main: 'rgba(41, 182, 246, 1)',
    light: 'rgba(79, 195, 247, 1)',
    dark: 'rgba(2, 136, 209, 1)',
    contrastText: '',
  },
  grey: {
    50: 'rgba(250, 250, 250, 1)',
    100: 'rgba(245, 245, 245, 1)',
    200: 'rgba(238, 238, 238, 1)',
    300: 'rgba(224, 224, 224, 1)',
    400: 'rgba(189, 189, 189, 1)',
    500: 'rgba(158, 158, 158, 1)',
    600: 'rgba(117, 117, 117, 1)',
    700: 'rgba(97, 97, 97, 1)',
    800: 'rgba(66, 66, 66, 1)',
    900: 'rgba(33, 33, 33, 1)',
    A100: 'rgba(213, 213, 213, 1)',
    A200: 'rgba(170, 170, 170, 1)',
    A400: 'rgba(97, 97, 97, 1)',
    A700: 'rgba(48, 48, 48, 1)',
  },
  contrastThreshold: 3,
  tonalOffset: 0.2,
  text: {
    primary: 'rgba(0, 0, 0, 0.87)',
    secondary: 'rgba(0, 0, 0, 0.54)',
    disabled: 'rgba(0, 0, 0, 0.38)',
    hint: 'rgba(0, 0, 0, 0.38)',
  },
  divider: 'rgba(0, 0, 0, 0.12)',
  background: {
    paper: 'rgba(245, 245, 245, 1)',
    default: 'rgba(255, 255, 255, 1)',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
  },
  action: {
    active: 'rgba(0, 0, 0, 0.54)',
    hover: 'rgba(235, 235, 235, 1)',
    hoverOpacity: 0.08,
    selected: 'rgba(0, 0, 0, 0.14)',
    disabled: 'rgba(0, 0, 0, 0.26)',
    disabledBackground: 'rgba(0, 0, 0, 0.12)',
  },
};

const paletteDark = {
  mode: ThemeStyle.Dark,
  common: {
    black: 'rgba(0, 0, 0, 1)',
    white: 'rgba(255, 255, 255, 1)',
    overlay: 'rgba(255, 255, 255, 0.5)',
  },
  primary: {
    main: 'rgba(255, 255, 255, 1)',
    light: 'rgba(127, 127, 127, 1)',
    dark: 'rgba(0, 0, 0, 1)',
    contrastText: 'rgba(255, 255, 255, 1)',
  },
  secondary: {
    main: 'rgba(252, 213, 53, 1)',
    light: 'rgba(222, 211, 169, 1)',
    dark: 'rgba(240, 185, 11, 1)',
    contrastText: 'rgba(255, 255, 255, 1)',
  },
  success: {
    main: 'rgba(102, 187, 106, 1)',
    light: 'rgba(173, 238, 176, 1)',
    dark: 'rgba(56, 142, 60, 1)',
    contrastText: '',
  },
  error: {
    main: 'rgba(244, 67, 54, 1)',
    light: 'rgba(231, 84, 84, 0.5)',
    dark: 'rgba(211, 47, 47, 1)',
    contrastText: '',
  },
  warning: {
    main: 'rgba(255, 167, 38, 1)',
    light: 'rgba(255, 183, 77, 1)',
    dark: 'rgba(245, 124, 0, 1)',
    contrastText: '',
  },
  info: {
    main: 'rgba(41, 182, 246, 1)',
    light: 'rgba(152, 201, 221, 1)',
    dark: 'rgba(2, 136, 209, 1)',
    contrastText: '',
  },
  grey: {
    50: 'rgba(250, 250, 250, 1)',
    100: 'rgba(245, 245, 245, 1)',
    200: 'rgba(238, 238, 238, 1)',
    300: 'rgba(224, 224, 224, 1)',
    400: 'rgba(189, 189, 189, 1)',
    500: 'rgba(158, 158, 158, 1)',
    600: 'rgba(117, 117, 117, 1)',
    700: 'rgba(97, 97, 97, 1)',
    800: 'rgba(66, 66, 66, 1)',
    900: 'rgba(33, 33, 33, 1)',
    A100: 'rgba(213, 213, 213, 1)',
    A200: 'rgba(170, 170, 170, 1)',
    A400: 'rgba(97, 97, 97, 1)',
    A700: 'rgba(48, 48, 48, 1)',
  },
  contrastThreshold: 3,
  tonalOffset: 0.2,
  text: {
    primary: 'rgba(255, 255, 255, 1)',
    secondary: 'rgba(255, 255, 255, 0.7)',
    disabled: 'rgba(255, 255, 255, 0.5)',
    hint: 'rgba(255, 255, 255, 0.5)',
  },
  divider: 'rgba(255, 255, 255, 0.12)',
  background: {
    paper: 'rgba(24, 26, 32, 1)',
    default: 'rgba(11, 14, 17, 1)',
    boxShadow: '0 2px 4px rgba(255, 255, 255, 0.1)',
  },
  action: {
    active: 'rgba(255, 255, 255, 1)',
    hover: 'rgba(48, 49, 55, 1)',
    hoverOpacity: 0.1,
    selected: 'rgba(255, 255, 255, 0.2)',
    disabled: 'rgba(255, 255, 255, 0.3)',
    disabledBackground: 'rgba(255, 255, 255, 0.12)',
  },
};

const breakpointValues: Record<Breakpoint, number> = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1536,
};
const breakpointUnit = 'px';
const breakpointStep = 5;

export const createTheme = (
  themeStyle: ThemeStyle,
  direction: 'ltr' | 'rtl',
): DefaultTheme => {
  const palette = themeStyle === ThemeStyle.Light ? paletteLight : paletteDark;

  return {
    direction,
    palette,
    breakpoints: {
      values: breakpointValues,
      up: (key) => {
        return `@media (min-width: ${breakpointValues[key]}${breakpointUnit})`;
      },
      down: (key) => {
        return `@media (max-width: ${breakpointValues[key] - breakpointStep / 100}${breakpointUnit})`;
      },
      between: (start, end) => {
        return (
          `@media (min-width: ${breakpointValues[start]}${breakpointUnit}) and ` +
          `(max-width: ${breakpointValues[end]}${breakpointUnit})`
        );
      },
    },
    zIndex: {
      drawer: 1000,
      tooltip: 5000,
      sidebar: 9000,
      modal: 10000,
    },
    typography: {
      h1: '3.75rem', // 60px / 16
      h2: '3.125rem', // 50px / 16
      h3: '2.625rem', // 42px / 16
      h4: '2.1875rem', // 35px / 16
      h5: '1.8125rem', // 29px / 16
      h6: '1.5rem', // 24px / 16
      p: '0.875rem', // 14px / 16
    },
  };
};
