import { Experience } from '../types/experience';

export const EMPTY_EXPERIENCE: Experience = {
  id: '',
  organization: '',
  position: '',
  location: {
    city: '',
    country: '',
  },
  tenure: {
    from: '',
    to: '',
  },
  skills: [],
  responsibilities: [
    {
      responsibility: '',
    },
    {
      responsibility: '',
    },
  ],
};

export const MIN_EXPERIENCE_RESPONSIBILITIES_NUMBER = 2;
export const MAX_EXPERIENCE_RESPONSIBILITIES_NUMBER = 6;
export const RESPONSIBILITY_MIN_LENGTH = 10;
export const RESPONSIBILITY_MAX_LENGTH = 300;
