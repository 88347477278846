import { FC } from 'react';

import { Portfolio } from '../../../types/portfolio';
import { Location } from '../../../components/location';
import { Talent as TalentIcon } from '../../../components/icons/common/talent';
import { Route } from '../../../enums/route';
import { Card } from '../../../components/card';
import { TagList } from '../../../components/tag-list';

interface IProps {
  portfolio: Portfolio;
}

export const Talent: FC<IProps> = ({ portfolio }) => {
  return (
    <Card
      to={`${Route.Talents}/${portfolio.user.id}`}
      picture={<TalentIcon width={40} />}
      content={
        <>
          <h3>{`${portfolio.user?.firstName} ${portfolio.user?.lastName}`}</h3>
          <p>{portfolio.occupation}</p>
          <TagList tags={portfolio.skills.slice(0, 5)} />
          <Location location={portfolio.location} />
        </>
      }
    />
  );
};
