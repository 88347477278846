import * as yup from 'yup';
import { MIN_PASSWORD_LENGTH } from '../constants/password';

export const updatePasswordSchema = yup
  .object({
    email: yup.string().email().required(),
    password: yup.string().required().min(MIN_PASSWORD_LENGTH),
    newPassword: yup
      .string()
      .notOneOf([yup.ref('password')])
      .required()
      .min(MIN_PASSWORD_LENGTH),
    confirmNewPassword: yup
      .string()
      .oneOf([yup.ref('newPassword')])
      .required(),
  })
  .required();
