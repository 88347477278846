import { createContext, Dispatch, SetStateAction, useContext } from 'react';

// Create the context with a default value of [false, a noop function]
export const SidebarContext = createContext<
  [boolean, Dispatch<SetStateAction<boolean>>] | undefined
>(undefined);

export function useSidebarContext(): [
  boolean,
  Dispatch<SetStateAction<boolean>>,
] {
  const context = useContext(SidebarContext);

  if (context === undefined) {
    throw new Error('SidebarContext.Provider is not used.');
  }

  return context;
}
