import { RefObject, useEffect } from 'react';

export const useOutsideClick = (
  ref: RefObject<HTMLElement>,
  onOutsideClick: () => void,
  options?: { enabled?: boolean },
) => {
  useEffect(() => {
    /**
     * Call onOutsideClick if clicked on outside of element
     */
    function handleOutsideClick(event: MouseEvent) {
      if (
        !!options?.enabled &&
        ref.current &&
        !ref.current.contains(event.target as HTMLElement)
      ) {
        onOutsideClick();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [onOutsideClick, options?.enabled, ref]);
};
