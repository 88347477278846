import {
  useState,
  useRef,
  useEffect,
  ReactNode,
  FC,
  Children,
  isValidElement,
  cloneElement,
} from 'react';
import * as PopperJS from '@popperjs/core';
import { usePopper } from 'react-popper';

import {
  DropdownWrapper,
  DropdownToggle,
  DropdownMenu,
} from './dropdown.styles';

interface IProps {
  children: ReactNode;
  placement?: PopperJS.Placement;
}

export const Dropdown: FC<IProps> = ({ children, placement = 'auto' }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleRef = useRef<HTMLButtonElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  // Using react-popper for menu positioning
  const { styles, attributes } = usePopper(toggleRef.current, menuRef.current, {
    placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
      {
        name: 'size',
        options: {
          boundariesElement: document.body,
        },
      },
    ],
  });

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as HTMLElement) &&
        toggleRef.current &&
        !toggleRef.current.contains(event.target as HTMLElement)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <DropdownWrapper>
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          switch (child.type) {
            case DropdownToggle:
              return cloneElement(
                child,
                {
                  ...child.props,
                  onClick: toggleDropdown,
                  ref: toggleRef,
                },
                child.props.children,
              );
            case DropdownMenu:
              return cloneElement(
                child,
                {
                  ...child.props,
                  isOpen,
                  style: styles.popper,
                  ...attributes.popper,
                  ref: menuRef,
                },
                child.props.children.map((c: ReactNode) => {
                  if (isValidElement(c)) {
                    return cloneElement(c, {
                      ...c.props,
                      onClick: () => {
                        setIsOpen(false);
                        c.props?.onClick();
                      },
                    });
                  }
                  return null;
                }),
              );
            default:
              return cloneElement(child, child.props, child.props.children);
          }
        }

        return null;
      })}
    </DropdownWrapper>
  );
};
