import { FC } from 'react';

import { Route } from '../../enums/route';
import { Container } from '../container';
import { Linkedin } from '../icons/common/linkedin';
import { Instagram } from '../icons/common/instagram';
import { Facebook } from '../icons/common/facebook';
import {
  Company,
  Content,
  Logo,
  Wrapper,
  SocialPlatforms,
  SocialPlatform,
  Copyright,
  LinkList,
  LinkListItem,
} from './footer.styles';

export const Footer: FC = () => {
  return (
    <Wrapper>
      <Container>
        <Content>
          <Company>
            <Logo href="/">{process.env.REACT_APP_TITLE}</Logo>
            <SocialPlatforms>
              {process.env.REACT_APP_LINKEDIN_URL && (
                <SocialPlatform>
                  <a
                    href={process.env.REACT_APP_LINKEDIN_URL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Linkedin width={32} />
                  </a>
                </SocialPlatform>
              )}
              {process.env.REACT_APP_INSTAGRAM_URL && (
                <SocialPlatform>
                  <a
                    href={process.env.REACT_APP_INSTAGRAM_URL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Instagram width={32} />
                  </a>
                </SocialPlatform>
              )}
              {process.env.REACT_APP_FACEBOOK_URL && (
                <SocialPlatform>
                  <a
                    href={process.env.REACT_APP_FACEBOOK_URL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Facebook width={32} />
                  </a>
                </SocialPlatform>
              )}
            </SocialPlatforms>
          </Company>
          <div></div>
        </Content>
        <div>
          <Copyright>
            {process.env.REACT_APP_TITLE} @ {new Date().getFullYear()}
          </Copyright>
          <LinkList>
            <LinkListItem>
              <a href={Route.TermsOfUse}>Terms of Use</a>
            </LinkListItem>
            <LinkListItem>
              <a href={Route.PrivacyPolicy}>Privacy Policy</a>
            </LinkListItem>
            <LinkListItem>
              <a href={Route.CookiePolicy}>Cookie Policy</a>
            </LinkListItem>
          </LinkList>
        </div>
      </Container>
    </Wrapper>
  );
};
