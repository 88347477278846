import styled, { css } from 'styled-components';

export const Wrapper = styled.div(
  () => css`
    margin-bottom: 12px;
    list-style: none;
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 4px;
  `,
);

export const Tag = styled.div(
  ({ theme }) => css`
    background-color: ${theme.palette.secondary.main};
    color: ${theme.palette.primary.dark};
    padding: 6px 8px;
    border-radius: 4px;
  `,
);

export const RemoveButton = styled.button(
  () => css`
    margin-left: 4px;
    background: none;
    border: none;
    cursor: pointer;
  `,
);
