import styled, { css } from 'styled-components';
import { Breakpoint } from '../../../enums/breakpoint';

export const Content = styled.div(
  ({ theme }) => css`
    margin-top: 24px;
    display: flex;
    gap: 24px;
    flex: 1;

    ${theme.breakpoints.up(Breakpoint.Medium)} {
      gap: 24px;
    }
  `,
);

export const LeftContent = styled.div(
  ({ theme }) => css`
    display: none;

    ${theme.breakpoints.up(Breakpoint.Medium)} {
      display: block;
      flex: 1;
    }
  `,
);

export const RightContent = styled.div(
  ({ theme }) => css`
    flex: 1;

    ${theme.breakpoints.up(Breakpoint.Medium)} {
      flex: none;
      width: 40%;
      align-self: flex-end;
    }
  `,
);
