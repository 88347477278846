import { FC } from 'react';

import { Experience as ExperienceType } from '../../../types/experience';
import { Location } from '../../../components/location';
import { Button } from '../../../components/form-elements/button';
import { Pen } from '../../../components/icons/common/pen';

interface IProps {
  isEditable: boolean;
  experience: ExperienceType;
  onEdit: (experienceId: string) => void;
}

export const Experience: FC<IProps> = ({ isEditable, experience, onEdit }) => {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '12px',
        }}
      >
        <h5 style={{ flex: 1, fontStyle: 'italic' }}>
          {experience.organization}
        </h5>
        {isEditable && (
          <Button
            variant="outlined"
            size="small"
            onClick={() => onEdit(experience.id!)}
          >
            <Pen />
          </Button>
        )}
      </div>
      <h6>{experience.position}</h6>
      <p>{`${experience.tenure.from} - ${experience.tenure.to}`}</p>
      <Location location={experience.location ?? { city: '', country: '' }} />
      <ul>
        {experience.responsibilities.map(
          ({ responsibility }, responsibilityIndex) => {
            return <li key={responsibilityIndex}>{responsibility}</li>;
          },
        )}
      </ul>
    </div>
  );
};
