import styled, { css, DefaultTheme } from 'styled-components';

const getUrl = (theme: DefaultTheme) =>
  `url("data:image/svg+xml;utf8,<svg fill='${theme.palette.text.hint}' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")`;

export const Wrapper = styled.div(
  () => css`
    margin-bottom: 12px;
  `,
);

type SelectComponentProps = {
  border?: boolean;
  error?: boolean;
};

export const SelectComponent = styled.select<SelectComponentProps>(
  ({ theme, border, error, disabled }) => css`
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    border-bottom: 1px solid ${theme.palette.divider};
    padding: 12px 16px;
    color: ${theme.palette.text.primary};
    background: ${theme.palette.background.paper} ${getUrl(theme)} no-repeat;
    background-position-x: calc(100% - 12px);
    background-position-y: calc(100% - 12px);

    appearance: none;

    ${disabled &&
    css`
      cursor: not-allowed;
    `}

    ${theme.direction === 'rtl' &&
    css`
      background-position-x: 20px;
      background-position-y: 20px;
    `}

    &:focus {
      outline: none;
    }

    ${border &&
    css`
      border: 1px solid ${theme.palette.divider};
    `}

    ${error &&
    css`
      border: 1px solid ${theme.palette.error.main};
    `}
  `,
);

export const Option = styled.option(
  ({ theme }) => css`
    color: ${theme.palette.primary.dark};
  `,
);

export const SelectError = styled.p(
  ({ theme }) => css`
    margin: 5px 0 0;
    font-size: 12px;
    color: ${theme.palette.error.dark};
  `,
);
