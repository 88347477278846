import React from 'react';

interface UserIconProps {
  color?: string;
  width?: number;
}

export const Talent: React.FC<UserIconProps> = ({
  color = 'currentColor',
  width = 16,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 26 26"
      fill="none"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      width={width}
      height={width}
    >
      <circle cx="12" cy="8" r="4" />

      {/* Left half of the body */}
      <path d="M4 20v-2c0-2.66 4.67-4 8-4" />

      {/* Star Icon, moved slightly higher */}
      <path
        fill={color}
        d="M17 15.5l1.47 3 3.28.48-2.37 2.3.56 3.27-2.94-1.55-2.94 1.55.56-3.27-2.37-2.3 3.28-.48 1.47-3z"
      />
    </svg>
  );
};
