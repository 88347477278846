import { useRef } from 'react';

export const usePortal = (elementId: string) => {
  let portalElement = document.getElementById(elementId) as HTMLElement;

  if (!portalElement) {
    portalElement = document.createElement('div');
    portalElement.id = elementId;
    document.body.appendChild(portalElement);
  }

  const portalElemRef = useRef(portalElement);

  return {
    portalElement: portalElemRef.current,
  };
};
