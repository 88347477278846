import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { Footer } from '../../footer';
import { Sidebar } from '../../sidebar';
import { Header } from '../../header';
import { Wrapper, Content } from './main.styles';

export const Main: FC = () => {
  return (
    <Wrapper>
      <Sidebar />
      <Content>
        <Header />
        <div style={{ flex: 1 }}>
          <Outlet />
        </div>
        <Footer />
      </Content>
    </Wrapper>
  );
};
