import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { User } from '../types/user';

export const UserContext = createContext<
  [User | undefined, Dispatch<SetStateAction<User | undefined>>] | undefined
>(undefined);

export function useUserContext(): [
  User | undefined,
  Dispatch<SetStateAction<User | undefined>>,
] {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error('UserContext.Provider is not used.');
  }

  return context;
}
