export const Pen = ({ color = 'currentColor', width = 16 }) => {
  return (
    <svg
      width={width}
      fill={color}
      viewBox="0 0 14 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11.8125 0C13.0206 0 14 0.979371 14 2.1875C14 2.67996 13.8372 3.13439 13.5625 3.5L12.6875 4.375L9.625 1.3125L10.5 0.4375C10.8656 0.162832 11.32 0 11.8125 0ZM0.875 10.0625L0 14L3.9375 13.125L12.0312 5.03125L8.96875 1.96875L0.875 10.0625ZM9.78329 4.97079L3.65829 11.0958L2.90421 10.3417L9.02921 4.21671L9.78329 4.97079Z" />
    </svg>
  );
};
