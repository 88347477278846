import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import * as yup from 'yup';

import { Route } from '../../enums/route';
import { signUp } from '../../api/auth';
import { getUser } from '../../api/user';
import { useUserContext } from '../../contexts/user';
import { Auth as AuthLayout } from '../../components/layouts/auth';
import { TextInput } from '../../components/form-elements/text-input';
import { Button } from '../../components/form-elements/button';
import { Gender } from '../../enums/gender';
import { Select } from '../../components/form-elements/select';
import { User } from '../../types/user';
import { signUpSchema } from '../../schemas/sign-up.schema';

export const SignUp: FC = () => {
  const navigate = useNavigate();
  const [, setUser] = useUserContext();

  const { t } = useTranslation(['auth', 'translation']);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(signUpSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
    },
  });

  const { mutate, isLoading } = useMutation(signUp, {
    onSuccess: async (data) => {
      const { accessToken, refreshToken } = data;

      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);

      const decoded = jwtDecode<User>(accessToken);

      if (decoded.id === undefined) {
        throw new Error('Invalid token');
      }

      const user = await getUser(decoded?.id);
      setUser(user);

      navigate(`${Route.Talents}/${user.id}`);
    },
    onError: console.error,
  });

  const onSubmit = (data: yup.InferType<typeof signUpSchema>) => {
    mutate(data);
  };

  return (
    <AuthLayout>
      <h5>{t('create-account')}</h5>
      <form style={{ marginTop: '20px' }} onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          {...register('firstName')}
          error={errors?.firstName?.message}
          border
          placeholder={t('translation:first-name')}
        />
        <TextInput
          {...register('lastName')}
          error={errors?.lastName?.message}
          border
          placeholder={t('translation:last-name')}
        />
        <Select
          {...register('gender')}
          error={errors?.gender?.message}
          border
          placeholder={t('gender')}
        >
          <option value={Gender.Male}>{t('male')}</option>
          <option value={Gender.Female}>{t('female')}</option>
        </Select>
        <TextInput
          {...register('email')}
          error={errors?.email?.message}
          border
          placeholder={t('translation:email')}
        />
        <TextInput
          {...register('password')}
          error={errors?.password?.message}
          border
          placeholder={t('translation:password')}
          type="password"
        />
        <div style={{ textAlign: 'right' }}>
          <Button
            type="submit"
            disabled={isLoading}
            variant="contained"
            size="small"
          >
            {t('translation:sign-up')}
          </Button>
        </div>
      </form>
    </AuthLayout>
  );
};
