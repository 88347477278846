import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { resetPassword } from '../../api/auth';
import { Auth } from '../../components/layouts/auth';

import { resetPasswordSchema } from '../../schemas/reset-password.schema';
import { TextInput } from '../../components/form-elements/text-input';
import { Button } from '../../components/form-elements/button';
import { Alert } from '../../components/alert';

export const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation(['auth', 'translation']);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(resetPasswordSchema),
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });

  const { mutate, isLoading, isSuccess, isError, error } = useMutation(
    (data: yup.InferType<typeof resetPasswordSchema>) =>
      resetPassword({
        id: searchParams.get('id') as string,
        token: searchParams.get('token') as string,
        ...data,
      }),
    {
      onError: console.error,
      onSuccess: () => {
        reset();
      },
    },
  );

  const onSubmit = (data: yup.InferType<typeof resetPasswordSchema>) => {
    mutate(data);
  };

  return (
    <Auth>
      <h5>Reset password</h5>
      <form style={{ marginTop: '20px' }} onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          type="password"
          {...register('password')}
          error={errors?.password?.message}
          border
          placeholder={t('translation:password')}
        />
        <TextInput
          type="password"
          {...register('confirmPassword')}
          error={errors?.confirmPassword?.message}
          border
          placeholder={t('translation:confirm-password')}
        />
        <div style={{ textAlign: 'right', marginBottom: '12px' }}>
          <Button
            type="submit"
            disabled={isLoading}
            variant="contained"
            size="small"
          >
            {t('translation:reset')}
          </Button>
        </div>
        {isError && (
          <Alert variant="danger">
            {t(`error:${error.response.data.errors[0].errorCode}`)}
          </Alert>
        )}
        {isSuccess && (
          <Alert variant="success">Password has been reset successfully</Alert>
        )}
      </form>
    </Auth>
  );
};
