import axios from 'axios';
import { Portfolio } from '../types/portfolio';
import { Experience } from '../types/experience';
import { Education } from '../types/education';

export const getPortfolio = async (id: string): Promise<Portfolio> => {
  const { data } = await axios.get<Portfolio>(`/portfolios/${id}`);
  return data;
};

export const getPortfolios = async (
  page: number,
  limit: number,
  filters?: Record<string, string | string[]>,
): Promise<{
  data: Portfolio[];
  total: number;
  page: number;
  limit: number;
}> => {
  const { data } = await axios.get<{
    data: Portfolio[];
    total: number;
    page: number;
    limit: number;
  }>(`/portfolios`, {
    params: {
      page,
      limit,
      ...filters,
    },
  });
  return data;
};

export const createPortfolio = async (
  payload: Omit<Portfolio, 'id' | 'user' | 'experiences' | 'educations'>,
): Promise<Portfolio> => {
  const { data } = await axios.post<Portfolio>(`/portfolios`, payload);
  return data;
};

export const updatePortfolio = async (
  payload: Omit<Portfolio, 'user' | 'experiences' | 'educations'>,
): Promise<Portfolio> => {
  const { data } = await axios.put<Portfolio>(
    `/portfolios/${payload.id}`,
    payload,
  );
  return data;
};

export const deletePortfolio = async (
  id: string,
): Promise<{ message: string }> => {
  const { data } = await axios.delete<{ message: string }>(`/portfolios/${id}`);
  return data;
};

export const createExperience = async (
  portfolioId: string,
  payload: Omit<Experience, 'id'>,
): Promise<{ message: string }> => {
  const { data } = await axios.post<{ message: string }>(
    `/portfolios/${portfolioId}/experiences`,
    payload,
  );
  return data;
};

export const updateExperience = async (
  portfolioId: string,
  experienceId: string,
  payload: Experience,
): Promise<{ message: string }> => {
  const { data } = await axios.put<{ message: string }>(
    `/portfolios/${portfolioId}/experiences/${experienceId}`,
    payload,
  );
  return data;
};

export const deleteExperience = async (
  portfolioId: string,
  experienceId: string,
): Promise<{ message: string }> => {
  const { data } = await axios.delete<{ message: string }>(
    `/portfolios/${portfolioId}/experiences/${experienceId}`,
  );
  return data;
};

export const updateEducation = async (
  portfolioId: string,
  educationId: string,
  payload: Education,
): Promise<{ message: string }> => {
  const { data } = await axios.put<{ message: string }>(
    `/portfolios/${portfolioId}/educations/${educationId}`,
    payload,
  );
  return data;
};

export const createEducation = async (
  portfolioId: string,
  payload: Omit<Education, 'id'>,
): Promise<{ message: string }> => {
  const { data } = await axios.post<{ message: string }>(
    `/portfolios/${portfolioId}/educations`,
    payload,
  );
  return data;
};

export const deleteEducation = async (
  portfolioId: string,
  educationId: string,
): Promise<{ message: string }> => {
  const { data } = await axios.delete<{ message: string }>(
    `/portfolios/${portfolioId}/educations/${educationId}`,
  );
  return data;
};
