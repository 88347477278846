import { FC, ReactNode } from 'react';
import { Container } from '../../container';
import { LeftContent, RightContent, Content } from './auth.styles';

interface IProps {
  children: ReactNode;
}

export const Auth: FC<IProps> = ({ children }) => {
  return (
    <Container>
      <Content>
        <LeftContent>
          <div />
        </LeftContent>
        <RightContent>{children}</RightContent>
      </Content>
    </Container>
  );
};
