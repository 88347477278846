import { Link } from 'react-router-dom';
import { Page } from '../../components/layouts/page';
import { Route } from '../../enums/route';

export const CookiePolicy = () => {
  return (
    <Page>
      <h3>Cookie Policy</h3>
      <ul>
        <li>
          At Talentsburg, we use cookies and similar tracking technologies to
          enhance your experience on our platform. This Cookie Policy explains
          what cookies are, how we use them, and how you can manage your cookie
          preferences.
        </li>
      </ul>

      <h4>1. What Are Cookies?</h4>
      <ul>
        <li>
          Cookies are small text files that are stored on your device when you
          visit a website. They help us remember information about your visit,
          such as your preferences and login details, so that your experience is
          smoother and more personalized. There are different types of cookies:
          <ul>
            <li>
              <strong>Session cookies:</strong> These are temporary cookies that
              are erased when you close your browser.
            </li>
            <li>
              <strong>Persistent cookies:</strong> These remain on your device
              for a set period or until you delete them.
            </li>
            <li>
              <strong>First-party cookies:</strong> These are cookies set by the
              website you are visiting.
            </li>
            <li>
              <strong>Third-party cookies:</strong> These are cookies set by
              external services or websites that are embedded on the site you're
              visiting.
            </li>
          </ul>
        </li>
      </ul>

      <h4>2. How We Use Cookies</h4>
      <ul>
        <li>
          We use cookies for several purposes, including:
          <ul>
            <li>
              <strong>Essential cookies:</strong> These cookies are necessary
              for the platform to function properly, allowing you to log in,
              navigate pages, and access secure areas.
            </li>
            <li>
              <strong>Performance and analytics cookies:</strong> These cookies
              collect information about how visitors use the platform, such as
              which pages are visited most often. This helps us improve the user
              experience.
            </li>
            <li>
              <strong>Functionality cookies:</strong> These cookies remember
              your preferences (e.g., language, region) to provide a more
              personalized experience.
            </li>
            <li>
              <strong>Advertising cookies:</strong> These cookies are used to
              show you relevant ads based on your browsing behavior on our
              platform and across the web.
            </li>
          </ul>
        </li>
      </ul>

      <h4>3. Cookies We Use</h4>
      <ul>
        <li>
          We use the following types of cookies on our platform:
          <ul>
            <li>
              <strong>Strictly necessary cookies:</strong> These are required to
              provide essential services, such as logging in and applying for
              jobs.
            </li>
            <li>
              <strong>Analytics and performance cookies:</strong> We use Google
              Analytics and other similar services to collect anonymous
              information about how users interact with our platform. This helps
              us understand usage trends and improve our services.
            </li>
            <li>
              <strong>Functionality cookies:</strong> These cookies allow us to
              remember your preferences and settings, such as language or
              display options.
            </li>
            <li>
              <strong>Advertising and targeting cookies:</strong> We may use
              these cookies to deliver personalized ads based on your browsing
              habits and interactions with our platform.
            </li>
          </ul>
        </li>
      </ul>

      <h4>4. Third-Party Cookies</h4>
      <ul>
        <li>
          Some cookies are placed by third parties on our platform, such as:
          <ul>
            <li>
              <strong>Google Analytics:</strong> To collect anonymous statistics
              on user interactions and traffic patterns.
            </li>
            <li>
              <strong>Social media integrations:</strong> If you interact with
              social media features, such as "Like" or "Share" buttons, the
              social media network may place a cookie on your device.
            </li>
            <li>
              <strong>Advertising networks:</strong> Third-party ad networks may
              use cookies to display targeted ads based on your browsing
              behavior across different websites.
            </li>
          </ul>
        </li>
      </ul>

      <h4>5. Managing Cookies</h4>
      <ul>
        <li>
          You have the option to manage and control cookies in various ways:
          <ul>
            <li>
              <strong>Browser settings:</strong> You can configure your browser
              to block or delete cookies. However, please note that disabling
              cookies may affect the functionality of certain parts of the
              platform.
            </li>
            <li>
              <strong>Cookie consent banner:</strong> When you first visit our
              site, you will see a banner asking for your consent to the use of
              cookies. You can choose to accept or decline non-essential cookies
              through this banner.
            </li>
          </ul>
        </li>
      </ul>

      <h4>6. Data Protection and Privacy</h4>
      <ul>
        <li>
          For more information about how we collect, store, and process your
          personal data, please review our{' '}
          <Link to={Route.PrivacyPolicy}>Privacy Policy</Link>.
        </li>
      </ul>

      <h4>7. Changes to This Cookie Policy</h4>
      <ul>
        <li>
          We may update this Cookie Policy from time to time to reflect changes
          in our use of cookies or for legal or regulatory reasons. Any changes
          will be effective when we post the updated policy on our platform, and
          we encourage you to review this page periodically.
        </li>
      </ul>
    </Page>
  );
};
