import axios from 'axios';
import { User } from '../types/user';
import { Portfolio } from '../types/portfolio';
import { Insights } from '../types/insight';

export const getUser = async (userId: string): Promise<User> => {
  const { data } = await axios.get<User>(`/users/${userId}`);
  return data;
};

export const getUserPortfolio = async (userId: string): Promise<Portfolio> => {
  const { data } = await axios.get<Portfolio>(`/users/${userId}/portfolio`);
  return data;
};

export const getUserInsights = async (
  userId: string,
): Promise<{
  experience: Insights;
  language: Insights;
}> => {
  const { data } = await axios.get<{
    experience: Insights;
    language: Insights;
  }>(`/users/${userId}/insights`);
  return data;
};
