import { useEffect, FC } from 'react';
import { useRoutes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { jwtDecode } from 'jwt-decode';

import { getRoutes } from './routes';
import { useUserContext } from './contexts/user';
import { User } from './types/user';
import { getUser } from './api/user';

export const App: FC = () => {
  const { i18n } = useTranslation();
  const [user, setUser] = useUserContext();

  const accessToken = localStorage.getItem('accessToken') ?? '';

  let decoded: User | null;
  try {
    decoded = jwtDecode(accessToken);
  } catch (error: unknown) {
    decoded = null;
  }

  const { isLoading } = useQuery<User | undefined>(
    ['user', decoded?.id],
    () => getUser(decoded?.id ?? ''),
    {
      onSuccess: setUser,
      onError: console.error,
      retry: false,
      enabled: decoded?.id !== undefined,
    },
  );

  const isAuthenticated = user !== undefined;

  const element = useRoutes(getRoutes(isAuthenticated));

  useEffect(() => {
    document.documentElement.lang = i18n.language;
    document.documentElement.dir = i18n.dir();
  }, [i18n, i18n.language]);

  if (isLoading) {
    return null;
  }

  return element;
};
