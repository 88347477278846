import { FC } from 'react';

import { Education as EducationType } from '../../../types/education';
import { Location } from '../../../components/location';
import { Button } from '../../../components/form-elements/button';
import { Pen } from '../../../components/icons/common/pen';

interface IProps {
  isEditable: boolean;
  education: EducationType;
  onEdit: (educationId: string) => void;
}

export const Education: FC<IProps> = ({ isEditable, education, onEdit }) => {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '12px',
        }}
      >
        <h5 style={{ flex: 1, fontStyle: 'italic' }}>
          {education.institution}
        </h5>
        {isEditable && (
          <Button
            variant="outlined"
            size="small"
            onClick={() => onEdit(education.id!)}
          >
            <Pen />
          </Button>
        )}
      </div>
      <h6>{education.fieldOfStudy}</h6>
      <p>{`${education.tenure.from} - ${education.tenure.to}`}</p>
      <Location location={education.location ?? { city: '', country: '' }} />
      <ul>
        <li>{education.degree}</li>
      </ul>
    </div>
  );
};
