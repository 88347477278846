import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Experience as ExperienceType } from '../../../types/experience';
import { Button } from '../../../components/form-elements/button';
import { Add } from '../../../components/icons/common/add';
import { Portfolio } from '../../../types/portfolio';
import {
  Section,
  SectionContent,
  SectionHeader,
  StartAddingButton,
} from '../talent.styles';
import { EMPTY_EXPERIENCE } from '../../../constants/experience';
import { ExperienceForm } from './experience-form';
import { Experience } from './experience';

interface IProps {
  isEditable: boolean;
  portfolio: Portfolio & { experiences?: ExperienceType[] };
  refetch: () => {};
}

export const Experiences: FC<IProps> = ({ isEditable, portfolio, refetch }) => {
  const { t } = useTranslation('profile');
  const [experience, setExperience] = useState<ExperienceType | null>(null);

  const { experiences = [] } = portfolio;

  return (
    <>
      <Section>
        <SectionHeader>
          <h4>{t('experience.title')}</h4>
          {isEditable && experiences.length > 0 && (
            <Button
              variant="contained"
              size="small"
              onClick={() => setExperience(EMPTY_EXPERIENCE)}
            >
              <Add />
            </Button>
          )}
        </SectionHeader>
        {isEditable && experiences.length === 0 && (
          <SectionContent style={{ textAlign: 'center' }}>
            <p>Start adding your work experience history</p>
            <StartAddingButton
              variant="contained"
              size="medium"
              onClick={() => setExperience(EMPTY_EXPERIENCE)}
            >
              <Add />
            </StartAddingButton>
          </SectionContent>
        )}
        {experiences && (
          <SectionContent>
            {experiences.map((experience) => {
              return (
                <Experience
                  key={experience.id}
                  isEditable={isEditable}
                  experience={experience}
                  onEdit={(experienceId) => {
                    const experience = experiences.find(
                      (experience) => experience.id === experienceId,
                    );

                    if (experience) {
                      setExperience(experience);
                    }
                  }}
                />
              );
            })}
          </SectionContent>
        )}
      </Section>
      {experience && (
        <ExperienceForm
          portfolio={portfolio}
          experience={experience}
          organizations={[]}
          onCancel={() => setExperience(null)}
          onSave={() => {
            setExperience(null);
            refetch();
          }}
        />
      )}
    </>
  );
};
