import * as yup from 'yup';
import { Gender } from '../enums/gender';
import { MIN_PASSWORD_LENGTH } from '../constants/password';

export const signUpSchema = yup
  .object({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    gender: yup.string().oneOf(Object.values(Gender)).required(),
    email: yup.string().email().required(),
    password: yup.string().required().min(MIN_PASSWORD_LENGTH),
  })
  .required();
