import styled, { css } from 'styled-components';

export const Wrapper = styled.div(
  () => css`
    position: relative;
    margin-bottom: 12px;
  `,
);

type ArrowProps = {
  open: boolean;
};

export const Arrow = styled.div<ArrowProps>(
  ({ theme, open }) => css`
    position: absolute;
    right: 18px;
    top: 25px;
    width: 0;
    height: 0;
    border-color: ${theme.palette.primary.contrastText} transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0;

    ${open &&
    css`
      border-color: transparent transparent
        ${theme.palette.primary.contrastText};
      border-width: 0 5px 5px;
    `}
  `,
);

export const SelectedValue = styled.div(
  () => css`
    box-sizing: border-box;
    border: 1px solid transparent;
  `,
);

export const Options = styled.div(
  ({ theme }) => css`
    background: ${theme.palette.background.paper};
    border-bottom: 1px solid ${theme.palette.divider};
    border-left: 1px solid ${theme.palette.divider};
    border-right: 1px solid ${theme.palette.divider};
    box-shadow: ${theme.palette.background.boxShadow};
    box-sizing: border-box;
    margin-top: -1px;
    max-height: 200px;
    overflow-y: auto;
    position: absolute;
    top: 50px;
    width: 100%;
    z-index: 1000;
    -webkit-overflow-scrolling: touch;
  `,
);

export const Option = styled.div(
  ({ theme }) => css`
    box-sizing: border-box;
    color: ${theme.palette.text.primary};
    cursor: pointer;
    display: block;
    padding: 12px 16px;

    &:hover {
      background: ${theme.palette.background.default};
    }
  `,
);
