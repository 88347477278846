import { Navigate } from 'react-router-dom';
import { Route } from './enums/route';
import { Main } from './components/layouts/main';
import { Error } from './pages/error';
import { Home } from './pages/home';
import { SignUp } from './pages/sign-up';
import { SignIn } from './pages/sign-in';
import { Talent } from './pages/talent';
import { ForgotPassword } from './pages/forgot-password';
import { ResetPassword } from './pages/reset-password';
import { Talents } from './pages/talents';
import { Insights } from './pages/insights';
import { Settings } from './pages/settings';
import { EmailVerification } from './pages/email-verification';
import { Jobs } from './pages/jobs';
import { Job } from './pages/job';
import { PrivacyPolicy } from './pages/privacy-policy';
import { TermsOfUse } from './pages/terms-of-use';
import { CookiePolicy } from './pages/cookie-policy';

export const getRoutes = (isAuthenticated: boolean) => {
  return [
    {
      path: Route.Home,
      element: <Main />,
      children: [
        {
          index: true,
          element: <Home />,
        },
        {
          path: Route.EmailVerification,
          element: <EmailVerification />,
        },
        {
          path: Route.ResetPassword,
          element: <ResetPassword />,
        },
        {
          path: Route.SignUp,
          element: isAuthenticated ? <Navigate to="/" /> : <SignUp />,
        },
        {
          path: Route.SignIn,
          element: isAuthenticated ? <Navigate to="/" /> : <SignIn />,
        },
        {
          path: Route.ForgotPassword,
          element: isAuthenticated ? <Navigate to="/" /> : <ForgotPassword />,
        },
        {
          path: Route.PrivacyPolicy,
          element: <PrivacyPolicy />,
        },
        {
          path: Route.TermsOfUse,
          element: <TermsOfUse />,
        },
        {
          path: Route.CookiePolicy,
          element: <CookiePolicy />,
        },
      ],
    },
    {
      path: Route.Settings,
      element: isAuthenticated ? <Main /> : <Navigate to={Route.SignIn} />,
      children: [
        {
          index: true,
          element: <Settings />,
        },
      ],
    },
    {
      path: Route.Talents,
      element: <Main />,
      children: [
        {
          index: true,
          element: <Talents />,
        },
        {
          path: `${Route.Talents}/:userId`,
          element: <Talent />,
        },
        {
          path: `${Route.Talents}/:userId/insights`,
          element: <Insights />,
        },
      ],
    },
    {
      path: Route.Jobs,
      element: <Main />,
      children: [
        {
          index: true,
          element: <Jobs />,
        },
        {
          path: `${Route.Jobs}/:jobId`,
          element: <Job />,
        },
      ],
    },
    {
      path: '*',
      element: <Main />,
      children: [
        {
          path: '*',
          element: <Error statusCode={404} />,
        },
      ],
    },
  ];
};
