import styled, { css } from 'styled-components';
import { Breakpoint } from '../../enums/breakpoint';

export const Wrapper = styled.div(
  ({ theme }) => css`
    margin: 16px auto;

    ${theme.breakpoints.up(Breakpoint.Large)} {
      display: flex;
    }
  `,
);

export const Filters = styled.div(
  ({ theme }) => css`
    ${theme.breakpoints.up(Breakpoint.Large)} {
      width: 30%;
      flex-shrink: 0;
      padding-right: 16px;
    }
  `,
);

export const Content = styled.div(
  ({ theme }) => css`
    ${theme.breakpoints.up(Breakpoint.Large)} {
      flex: 1;
      overflow: auto;
      padding-inline-start: 16px;
      border-left: 1px solid ${theme.palette.divider};
    }
  `,
);
