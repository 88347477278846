import styled, { css } from 'styled-components';
import { Button } from '../form-elements/button';

export const Wrapper = styled.div(
  () => css`
    margin-bottom: 12px;
    display: flex;
  `,
);

export const AddButton = styled(Button)(
  ({ theme }) => css`
    width: 50px;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    border-top: 1px solid transparent;
    border-radius: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: ${theme.palette.background.paper};
  `,
);
