import styled, { css } from 'styled-components';

export const Wrapper = styled.div(
  () => css`
    display: flex;
  `,
);

export const Content = styled.div(
  () => css`
    flex: 1;
    min-height: 100vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
  `,
);
