import styled, { css } from 'styled-components';
import { Breakpoint } from '../../enums/breakpoint';

export const Wrapper = styled.div(
  ({ theme }) => css`
    margin: 12px auto;

    ${theme.breakpoints.up(Breakpoint.Medium)} {
      display: flex;
    }
  `,
);

export const LeftContent = styled.div(
  ({ theme }) => css`
    display: none;

    ${theme.breakpoints.up(Breakpoint.Medium)} {
      display: block;
      flex: 1;
    }
  `,
);

export const RightContent = styled.div(
  ({ theme }) => css`
    flex: 1;

    ${theme.breakpoints.up(Breakpoint.Medium)} {
      flex: none;
      width: 40%;
      align-self: flex-end;
    }
  `,
);
