import styled from 'styled-components';

export const Wrapper = styled.ul`
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
`;

export const Item = styled.li`
  margin: 0 6px;

  &:first-child {
    margin-inline-start: 0;
  }

  &:last-child {
    margin-inline-end: 0;
  }
`;
