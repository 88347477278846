import styled, { css } from 'styled-components';

export const Button = styled.button(
  ({ theme }) => css`
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    padding: 6px 12px;
    color: ${theme.palette.text.primary};
    background-color: ${theme.palette.background.default};
    border: 1px solid ${theme.palette.divider};

    &:hover {
      cursor: pointer;
    }
  `,
);

export const DatePickerContainer = styled.div`
  position: relative;
`;

export const Calendar = styled.div(
  ({ theme }) => css`
    position: absolute;
    background: ${theme.palette.background.paper};
    border: 1px solid ${theme.palette.divider};
    box-shadow: 0 2px 5px ${theme.palette.background.boxShadow};
    z-index: 1000;
  `,
);

export const CalendarHeader = styled.div(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background: ${theme.palette.background.default};
    border-bottom: 1px solid ${theme.palette.divider};
  `,
);

export const CalendarBody = styled.div`
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
`;

export const WeekDay = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
`;

type DayPropsType = {
  selected: boolean;
  empty: boolean;
};

export const Day = styled.div<DayPropsType>(
  ({ selected, empty, theme }) => css`
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 4px;

    ${!empty &&
    css`
      &:hover {
        background: ${theme.palette.secondary.dark};
        cursor: pointer;
      }
    `}

    ${selected &&
    css`
      background: ${theme.palette.secondary.main};
    `}
  `,
);

export const Select = styled.select(
  ({ theme }) => css`
    margin: 0 5px;
    background-color: ${theme.palette.background.default};
    border: 1px solid ${theme.palette.divider};
    color: ${theme.palette.text.primary};
    padding: 5px;
  `,
);
