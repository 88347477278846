import { forwardRef, InputHTMLAttributes } from 'react';
import { Error, Wrapper, Input } from './text-input.styles';

export interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  border?: boolean;
  error?: string;
}

export const TextInput = forwardRef<HTMLInputElement, IProps>(
  ({ style, ...props }, ref) => {
    return (
      <Wrapper style={style}>
        <Input
          {...props}
          ref={ref}
          border={!!props.border}
          error={!!props.error}
        />
        {!!props.error && <Error>{props.error}</Error>}
      </Wrapper>
    );
  },
);
