import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { TextInput } from '../../components/form-elements/text-input';
import { Button } from '../../components/form-elements/button';
import { updatePassword } from '../../api/auth';
import { useMutation } from 'react-query';
import { useUserContext } from '../../contexts/user';
import { updatePasswordSchema } from '../../schemas/update-password.schema';
import { Alert } from '../../components/alert';

export const UpdatePasswordForm: FC = () => {
  const [user] = useUserContext();
  const { t } = useTranslation(['settings', 'translation']);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(updatePasswordSchema),
    defaultValues: {
      email: user?.email,
      password: '',
      newPassword: '',
    },
  });

  const { mutate, isSuccess, isError } = useMutation(updatePassword);

  return (
    <>
      <h5>{t('update-password')}</h5>
      <form
        style={{ marginTop: '20px' }}
        onSubmit={handleSubmit(
          async (data: yup.InferType<typeof updatePasswordSchema>) =>
            mutate(data),
        )}
      >
        <TextInput
          {...register('password')}
          error={errors?.password?.message}
          border
          placeholder={t('translation:password')}
          type="password"
        />
        <TextInput
          {...register('newPassword')}
          error={errors?.newPassword?.message}
          border
          placeholder={t('translation:new-password')}
          type="password"
        />
        <TextInput
          {...register('confirmNewPassword')}
          error={errors?.confirmNewPassword?.message}
          border
          placeholder={t('translation:confirm-new-password')}
          type="password"
        />
        <div style={{ textAlign: 'right', marginBottom: '12px' }}>
          <Button type="submit" variant="contained" size="small">
            {t('translation:update')}
          </Button>
        </div>
        {isSuccess && (
          <Alert variant="success">
            {t('translation:password-update-success')}
          </Alert>
        )}
        {isError && (
          <Alert variant="danger">
            {t('translation:password-update-failure')}
          </Alert>
        )}
      </form>
    </>
  );
};
