import { FC, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { NavbarWrapper } from './navbar.styles';

interface IProps {
  children: ReactNode;
}

export const NavbarPortal: FC<IProps> = ({ children }) => {
  const portalElement = document.getElementById('navbar-portal');

  if (portalElement === null) {
    return null;
  }

  return createPortal(<NavbarWrapper>{children}</NavbarWrapper>, portalElement);
};
