import { FC, ReactNode } from 'react';

import { Container } from '../../container';
import { Wrapper } from './page.styles';

export const Page: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Wrapper>
      <Container>{children}</Container>
    </Wrapper>
  );
};
