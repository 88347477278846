import { FC } from 'react';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { DOTS, usePagination } from './pagination.hook';
import { Button } from '../form-elements/button';
import { Wrapper, Item } from './pagination.styles';
import { ChevronLeft } from '../icons/common/chevron-left';
import { ChevronRight } from '../icons/common/chevron-right';

interface IProps {
  onPageChange: (page: number) => void;
  total: number;
  currentPage: number;
  limit: number;
  siblingCount?: number;
}

export const Pagination: FC<IProps> = ({
  onPageChange,
  total,
  currentPage,
  limit,
  siblingCount = 1,
}) => {
  const theme = useTheme();
  const { i18n } = useTranslation();

  const paginationRange = usePagination({
    currentPage,
    total,
    siblingCount,
    limit,
  });

  if (paginationRange === undefined) {
    return null;
  }

  if (currentPage === 0 || (paginationRange && paginationRange.length < 2)) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];

  return (
    <Wrapper>
      {currentPage !== 1 && (
        <Item>
          <Button size="small" variant="text" onClick={onPrevious}>
            {i18n.dir() === 'ltr' ? (
              <ChevronLeft width={8} color={theme.palette.text.primary} />
            ) : (
              <ChevronRight width={8} color={theme.palette.text.primary} />
            )}
          </Button>
        </Item>
      )}
      {paginationRange.map((pageNumber, index) => {
        if (pageNumber === DOTS) {
          return <Item key={index}>&#8230;</Item>;
        }
        return (
          <Item key={index}>
            <Button
              variant={pageNumber === currentPage ? 'contained' : 'text'}
              size="small"
              onClick={() => onPageChange(pageNumber as number)}
            >
              {pageNumber}
            </Button>
          </Item>
        );
      })}
      {currentPage !== lastPage && (
        <Item>
          <Button size="small" variant="text" onClick={onNext}>
            {i18n.dir() === 'ltr' ? (
              <ChevronRight
                width={8}
                color={theme.palette.primary.contrastText}
              />
            ) : (
              <ChevronLeft
                width={8}
                color={theme.palette.primary.contrastText}
              />
            )}
          </Button>
        </Item>
      )}
    </Wrapper>
  );
};
