import styled, { css } from 'styled-components';
import { Breakpoint } from '../../enums/breakpoint';
import { Button } from '../form-elements/button';

export const Content = styled.div(
  () => css`
    padding-top: 24px;
    padding-bottom: 24px;
  `,
);

export const ContentRow = styled.div(
  ({ theme }) => css`
    ${theme.breakpoints.up(Breakpoint.Medium)} {
      display: flex;
    }
  `,
);

export const LeftContent = styled.div(
  ({ theme }) => css`
    ${theme.breakpoints.up(Breakpoint.Medium)} {
      padding: 0 16px 0 0;
      border-right: 1px solid ${theme.palette.divider};
      flex: 1;
    }
  `,
);

export const RightContent = styled.div(
  ({ theme }) => css`
    ${theme.breakpoints.up(Breakpoint.Medium)} {
      padding: 0 0 0 16px;
      flex: 1;
    }
  `,
);

export const Footer = styled.div(
  ({ theme }) => css`
    padding: 16px 0;
    border-top: 1px solid ${theme.palette.divider};
  `,
);

export const SaveButton = styled(Button)(
  ({ theme }) => css`
    ${theme.breakpoints.up(Breakpoint.Medium)} {
      width: 100px;
    }
  `,
);

export const CancelButton = styled(Button)(
  ({ theme }) => css`
    margin-right: 16px;
    margin-left: auto;

    ${theme.breakpoints.up(Breakpoint.Medium)} {
      width: 100px;
    }
  `,
);

export const DeleteButton = styled(Button)(
  ({ theme }) => css`
    margin-right: 16px;
    justify-self: flex-start;

    ${theme.breakpoints.up(Breakpoint.Medium)} {
      width: 100px;
    }
  `,
);
