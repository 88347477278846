import { useSidebarContext } from '../../contexts/sidebar';
import { Button } from '../form-elements/button';
import { CloseMenu } from '../icons/common/close-menu';
import { BurgerMenu } from '../icons/common/burger-menu';
import { Wrapper } from './sidebar-toggle.styles';

export const SidebarToggle = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useSidebarContext();

  return (
    <Wrapper>
      <Button onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
        {isSidebarOpen ? <CloseMenu /> : <BurgerMenu />}
      </Button>
    </Wrapper>
  );
};
