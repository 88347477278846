import { createPortal } from 'react-dom';
import { FC, useRef, ReactNode, useEffect } from 'react';

import { usePortal } from '../../hooks/portal.hook';
import { useOutsideClick } from '../../hooks/outside-click.hook';
import { Overlay, Wrapper, Content } from './modal.styles';

interface IProps {
  children: ReactNode;
  isPersistent?: boolean;
  onClose: () => void;
}

export const Modal: FC<IProps> = ({
  children,
  onClose,
  isPersistent = false,
}) => {
  const contentRef = useRef(null);

  const { portalElement } = usePortal('modal-portal');

  useOutsideClick(contentRef, () => {
    if (!isPersistent) {
      onClose();
    }
  });

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  useEffect(() => {
    function onEsc(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        onClose();
      }
    }

    document.addEventListener('keydown', onEsc);

    return () => {
      document.removeEventListener('keydown', onEsc);
    };
  }, [onClose]);

  return createPortal(
    <Overlay>
      <Wrapper>
        <Content ref={contentRef}>{children}</Content>
      </Wrapper>
    </Overlay>,
    portalElement,
  );
};
