import { FC, useEffect, useRef } from 'react';
import {
  Chart,
  DoughnutController,
  ArcElement,
  Legend,
  Colors,
  Tooltip,
} from 'chart.js';
import { useTheme } from 'styled-components';

Chart.register(DoughnutController, ArcElement, Legend, Colors, Tooltip);

interface IProps {
  labels: string[];
  datasets: {
    data: number[];
  }[];
}

export const DoughnutChart: FC<IProps> = ({ labels, datasets }) => {
  const theme = useTheme();
  const chartRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const chart = new Chart(chartRef.current as unknown as HTMLCanvasElement, {
      type: 'doughnut',
      data: {
        labels: labels,
        datasets: datasets.map((dataset) => {
          return {
            label: 'Skills',
            data: dataset.data,
            // backgroundColor: [],
            // borderColor: [],
            // borderWidth: 1,
          };
        }),
      },
      options: {
        // responsive: true,
        // maintainAspectRatio: true,
        plugins: {
          //   title: {
          //     display: true,
          //     text: 'Title',
          //     color: 'red',
          //   },
          legend: {
            //   display: true,
            labels: {
              color: theme.palette.text.primary,
              font: {
                size: 12,
              },
            },
          },
          tooltip: {
            // enabled: false,
            // callbacks: {
            //   title: () => {
            //     return '';
            //   },
            //   label: (context) => {
            //     return '';
            //   },
            // },
          },
        },
      },
    });

    return () => {
      chart.destroy();
    };
  }, [datasets, labels, theme.palette.text.primary]);

  return <canvas ref={chartRef} />;
};
