import { FC, ReactNode } from 'react';
import { Wrapper, PictureWrapper, Picture, Details } from './card.styles';

interface IProps {
  picture: ReactNode;
  content: ReactNode;
  to: string;
}

export const Card: FC<IProps> = ({ to, picture, content }) => {
  return (
    <Wrapper to={to}>
      <PictureWrapper>
        <Picture>{picture}</Picture>
      </PictureWrapper>
      <Details>{content}</Details>
    </Wrapper>
  );
};
