import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { DatePicker } from '../../../components/form-elements/datepicker';
import { TextInput } from '../../../components/form-elements/text-input';
import { Dropdown } from '../../../components/form-elements/dropdown';
import { Education as EducationType } from '../../../types/education';
import { educationSchema } from '../../../schemas/education.schema';
import { ProfileModal } from '../../../components/profile-modal/profile-modal';
import {
  ContentRow,
  LeftContent,
  RightContent,
} from '../../../components/profile-modal/profile-modal.styles';
import {
  createEducation,
  deleteEducation,
  updateEducation,
} from '../../../api/portfolio';
import { Portfolio } from '../../../types/portfolio';

interface IProps {
  portfolio: Portfolio;
  institutions: {
    value: string;
    label: string;
  }[];
  education: EducationType;
  onCancel: () => void;
  onSave: () => void;
}

export const EducationForm: FC<IProps> = ({
  portfolio,
  institutions,
  education,
  onCancel,
  onSave,
}) => {
  const { t } = useTranslation('profile');

  const { mutate: create } = useMutation(
    async (data: Omit<EducationType, 'id'>) => {
      await createEducation(portfolio.id, data);
      onSave();
    },
  );

  const { mutate: update } = useMutation(async (data: EducationType) => {
    await updateEducation(portfolio.id, data.id, data);
    onSave();
  });

  const { mutate: del } = useMutation(async (educationId: string) => {
    await deleteEducation(portfolio.id, educationId);
    onSave();
  });

  const { control, reset, formState, getValues, handleSubmit } = useForm<
    yup.InferType<typeof educationSchema>
  >({
    mode: 'onChange',
    resolver: yupResolver(educationSchema),
    defaultValues: education,
  });

  return (
    <ProfileModal
      onCancel={() => {
        reset();
        onCancel();
      }}
      onDelete={
        education.id
          ? () => {
              const { id } = getValues();
              if (id) {
                del(id);
              }
            }
          : undefined
      }
      onSave={handleSubmit((data) => {
        if (data.id === undefined) {
          create(data);
        } else {
          update(data as EducationType);
        }
      })}
    >
      <ContentRow>
        <LeftContent>
          <h4>Education</h4>
        </LeftContent>
        <RightContent>
          <h6>Institution</h6>
          <Controller
            name="institution"
            control={control}
            render={({ field }) => {
              return (
                <Dropdown
                  placeholder="Insitution"
                  options={institutions}
                  onChange={(option) => {
                    field.onChange(option.value, {
                      shouldValidate: true,
                    });
                  }}
                  value={field.value}
                  error={formState.errors.institution?.message}
                  allowNew
                />
              );
            }}
          />
          <h6>Degree</h6>
          <Controller
            name="degree"
            control={control}
            render={({ field }) => {
              return (
                <TextInput
                  {...field}
                  placeholder="Degree"
                  error={formState.errors.degree?.message}
                />
              );
            }}
          />
          <h6>{t('translation:field-of-study')}</h6>
          <Controller
            name="fieldOfStudy"
            control={control}
            render={({ field }) => {
              return (
                <TextInput
                  {...field}
                  placeholder="Field of Study"
                  error={formState.errors.fieldOfStudy?.message}
                />
              );
            }}
          />
          <h6>{t('translation:tenure')}</h6>
          <div
            style={{
              display: 'flex',
              gap: '12px',
            }}
          >
            <div style={{ flex: 1 }}>
              <Controller
                name="tenure.from"
                control={control}
                render={({ field }) => {
                  return (
                    <DatePicker
                      {...field}
                      placeholder={t('translation:from')}
                      error={formState.errors.tenure?.from?.message}
                    />
                  );
                }}
              />
            </div>
            <div style={{ flex: 1 }}>
              <Controller
                name="tenure.to"
                control={control}
                render={({ field }) => {
                  return (
                    <DatePicker
                      {...field}
                      placeholder={t('translation:to')}
                      error={formState.errors.tenure?.to?.message}
                    />
                  );
                }}
              />
            </div>
          </div>
          <h6>{t('translation:location')}</h6>
          <div
            style={{
              display: 'flex',
              gap: '12px',
            }}
          >
            <div style={{ flex: 1 }}>
              <Controller
                name="location.city"
                control={control}
                render={({ field }) => {
                  return (
                    <TextInput
                      {...field}
                      placeholder={t('translation:city')}
                      error={formState.errors.location?.city?.message}
                    />
                  );
                }}
              />
            </div>
            <div style={{ flex: 1 }}>
              <Controller
                name="location.country"
                control={control}
                render={({ field }) => {
                  return (
                    <TextInput
                      {...field}
                      placeholder={t('translation:country')}
                      error={formState.errors.location?.country?.message}
                    />
                  );
                }}
              />
            </div>
          </div>
        </RightContent>
      </ContentRow>
    </ProfileModal>
  );
};
