import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Education as EducationType } from '../../../types/education';
import {
  Section,
  SectionContent,
  SectionHeader,
  StartAddingButton,
} from '../talent.styles';
import { Education } from './education';
import { Button } from '../../../components/form-elements/button';
import { Add } from '../../../components/icons/common/add';
import { EducationForm } from './education-form';
import { Portfolio } from '../../../types/portfolio';
import { EMPTY_EDUCATION } from '../../../constants/education';

interface IProps {
  isEditable: boolean;
  portfolio: Portfolio & { educations?: EducationType[] };
  refetch: () => {};
}

export const Educations: FC<IProps> = ({ isEditable, portfolio, refetch }) => {
  const { t } = useTranslation('profile');
  const [education, setEducation] = useState<EducationType | null>(null);

  const { educations = [] } = portfolio;

  return (
    <>
      <Section>
        <SectionHeader>
          <h4>{t('education.title')}</h4>
          {isEditable && educations.length > 0 && (
            <Button
              variant="contained"
              size="small"
              onClick={() => setEducation(EMPTY_EDUCATION)}
            >
              <Add />
            </Button>
          )}
        </SectionHeader>
        {isEditable && educations.length === 0 && (
          <SectionContent style={{ textAlign: 'center' }}>
            <p>Start adding your education history</p>
            <StartAddingButton
              variant="contained"
              size="medium"
              onClick={() => setEducation(EMPTY_EDUCATION)}
            >
              <Add />
            </StartAddingButton>
          </SectionContent>
        )}
        {educations && (
          <SectionContent>
            {educations.map((education) => {
              return (
                <Education
                  key={education.id}
                  isEditable={isEditable}
                  education={education}
                  onEdit={(educationId) => {
                    const education = educations.find(
                      (education) => education.id === educationId,
                    );

                    if (education) {
                      setEducation(education);
                    }
                  }}
                />
              );
            })}
          </SectionContent>
        )}
      </Section>
      {education && (
        <EducationForm
          portfolio={portfolio}
          education={education}
          institutions={[]}
          onCancel={() => setEducation(null)}
          onSave={() => {
            setEducation(null);
            refetch();
          }}
        />
      )}
    </>
  );
};
