import { Page } from '../../components/layouts/page';

export const TermsOfUse = () => {
  return (
    <Page>
      <h3>Terms of Use</h3>
      <ul>
        <li>
          <strong>Last updated:</strong> October 2024
        </li>
        <li>
          Welcome to Talentsburg. By accessing or using our platform, you agree
          to comply with these Terms of Use ("Terms"). If you do not agree with
          these Terms, you must not use our services. These Terms govern your
          use of Talentsburg as a talent, hiring manager, or visitor.
        </li>
      </ul>

      <h4>1. Use of the Platform</h4>
      <ul>
        <li>
          You agree to use Talentsburg for its intended purposes, including
          creating profiles, applying for jobs, and posting job opportunities.
          Specifically:
          <ul>
            <li>
              <strong>Talents:</strong> You may create an account, build your
              profile, and apply for jobs posted on the platform.
            </li>
            <li>
              <strong>Hiring Managers:</strong> You may post job opportunities,
              review applications, and communicate with talents.
            </li>
            <li>
              <strong>Prohibited uses:</strong> You agree not to misuse the
              platform, including but not limited to:
              <ul>
                <li>Posting false or misleading information.</li>
                <li>Harassing or discriminating against other users.</li>
                <li>
                  Attempting to gain unauthorized access to another user's
                  account.
                </li>
                <li>Violating any applicable laws or regulations.</li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <h4>2. Account Registration and Responsibilities</h4>
      <ul>
        <li>
          When you create an account on Talentsburg, you agree to:
          <ul>
            <li>Provide accurate, current, and complete information.</li>
            <li>Maintain the confidentiality of your account credentials.</li>
            <li>
              Be responsible for all activities that occur under your account.
            </li>
            <li>
              Notify us immediately of any unauthorized use of your account.
            </li>
          </ul>
        </li>
      </ul>

      <h4>3. Job Postings and Applications</h4>
      <ul>
        <li>
          <strong>Job Postings:</strong>
          <ul>
            <li>
              Hiring managers are responsible for ensuring that their job
              listings are accurate and lawful.
            </li>
            <li>
              Talentsburg does not guarantee the accuracy or availability of any
              job postings.
            </li>
          </ul>
        </li>
        <li>
          <strong>Applications:</strong>
          <ul>
            <li>
              Talents are responsible for ensuring the accuracy of their
              profiles and application materials.
            </li>
            <li>
              Hiring managers are responsible for reviewing applications and
              making hiring decisions.
            </li>
          </ul>
        </li>
      </ul>

      <h4>4. Fees and Payments</h4>
      <ul>
        <li>
          Some features of Talentsburg may require payment. You agree to:
          <ul>
            <li>
              Pay any applicable fees as described when purchasing paid
              services.
            </li>
            <li>Provide accurate billing information.</li>
            <li>
              Understand that fees are non-refundable, unless explicitly stated
              otherwise.
            </li>
          </ul>
        </li>
      </ul>

      <h4>5. Intellectual Property</h4>
      <ul>
        <li>
          Talentsburg and its content, features, and functionality (including
          but not limited to all information, software, text, displays, images,
          and audio) are owned by Talentsburg and are protected by copyright,
          trademark, and other intellectual property laws. Specifically:
          <ul>
            <li>
              <strong>User content:</strong> You retain ownership of any content
              you submit to the platform but grant Talentsburg a license to use,
              modify, and display that content for the purposes of providing our
              services.
            </li>
            <li>
              <strong>Platform content:</strong> You agree not to reproduce,
              distribute, modify, or create derivative works from our platform
              content without prior written permission.
            </li>
          </ul>
        </li>
      </ul>

      <h4>6. Termination</h4>
      <ul>
        <li>
          Talentsburg reserves the right to suspend or terminate your account if
          you violate these Terms or engage in unlawful or harmful behavior.
          Specifically:
          <ul>
            <li>
              We may suspend or terminate your access without notice if we
              believe you have violated these Terms.
            </li>
            <li>
              You may also terminate your account at any time by contacting us.
            </li>
          </ul>
        </li>
      </ul>

      <h4>7. Disclaimers and Limitation of Liability</h4>
      <ul>
        <li>
          <strong>Disclaimers:</strong>
          <ul>
            <li>
              Talentsburg is provided "as is" without any warranties, express or
              implied.
            </li>
            <li>
              We do not guarantee that our platform will be error-free or
              available at all times.
            </li>
            <li>
              We are not responsible for the actions or content of users on the
              platform.
            </li>
          </ul>
        </li>
        <li>
          <strong>Limitation of liability:</strong>
          <ul>
            <li>
              In no event shall Talentsburg or its affiliates be liable for any
              indirect, incidental, special, or consequential damages arising
              from your use of the platform.
            </li>
          </ul>
        </li>
      </ul>

      <h4>8. Governing Law</h4>
      <ul>
        <li>
          These Terms of Use are governed by and construed in accordance with
          the laws of [Your Country], without regard to its conflict of law
          provisions.
        </li>
      </ul>

      <h4>9. Changes to These Terms</h4>
      <ul>
        <li>
          We may modify these Terms of Use from time to time. Any changes will
          be effective immediately upon posting. We will notify you of any
          significant changes, and your continued use of the platform
          constitutes acceptance of the updated Terms.
        </li>
      </ul>
    </Page>
  );
};
