import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import { useUserContext } from '../../contexts/user';
import { getUserPortfolio } from '../../api/user';
import { Route } from '../../enums/route';
import { Container } from '../../components/container';
import { Experiences } from './components/experiences';
import { Educations } from './components/educations';
import { About } from './components/about';
import {
  History,
  Wrapper,
  PortfolioWrapper,
  PortfolioContent,
} from './talent.styles';
import { Navbar, NavbarPortal, NavbarLink } from '../../components/navbar';

export const Talent: FC = () => {
  const { t } = useTranslation();
  const { userId } = useParams<{ userId: string }>();
  const [user] = useUserContext();

  const {
    data: portfolio,
    refetch,
    isLoading,
  } = useQuery(['portfolio', userId], () => getUserPortfolio(userId!), {
    onError: console.error,
    retry: false,
  });

  const isEditable = useMemo(() => {
    return user?.id === userId;
  }, [user?.id, userId]);

  if (isLoading) {
    return (
      <Container>
        <Wrapper>Loading...</Wrapper>
      </Container>
    );
  }

  if (!portfolio) {
    return (
      <Container>
        <Wrapper>Portfolio not found</Wrapper>
      </Container>
    );
  }

  return (
    <>
      <NavbarPortal>
        <Navbar>
          <NavbarLink to={`${Route.Talents}/${userId}`} end>
            {t('profile')}
          </NavbarLink>
          <NavbarLink to={`${Route.Talents}/${userId}/insights`}>
            {t('insights')}
          </NavbarLink>
        </Navbar>
      </NavbarPortal>
      <Container>
        <Wrapper>
          <PortfolioWrapper>
            <PortfolioContent>
              <About
                isEditable={isEditable}
                portfolio={portfolio}
                refetch={refetch}
              />
            </PortfolioContent>
          </PortfolioWrapper>
          {portfolio && (
            <History>
              <Experiences
                isEditable={isEditable}
                portfolio={portfolio}
                refetch={refetch}
              />
              <Educations
                isEditable={isEditable}
                portfolio={portfolio}
                refetch={refetch}
              />
            </History>
          )}
        </Wrapper>
      </Container>
    </>
  );
};
