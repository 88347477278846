import { Education } from '../types/education';

export const EMPTY_EDUCATION: Education = {
  id: '',
  institution: '',
  degree: '',
  fieldOfStudy: '',
  location: {
    city: '',
    country: '',
  },
  tenure: {
    from: '',
    to: '',
  },
};
