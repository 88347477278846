import { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { jwtDecode } from 'jwt-decode';
import { useMutation } from 'react-query';
import * as yup from 'yup';

import { Route } from '../../enums/route';
import { signIn } from '../../api/auth';
import { getUser } from '../../api/user';
import { useUserContext } from '../../contexts/user';
import { Auth as AuthLayout } from '../../components/layouts/auth';
import { TextInput } from '../../components/form-elements/text-input';
import { Button } from '../../components/form-elements/button';
import { User } from '../../types/user';
import { signInSchema } from '../../schemas/sign-in.schema';
import { Alert } from '../../components/alert';

export const SignIn: FC = () => {
  const { t } = useTranslation(['auth', 'translation', 'error']);
  const navigate = useNavigate();
  const [, setUser] = useUserContext();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(signInSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const { mutate, isError, error, isLoading } = useMutation(signIn, {
    onSuccess: async (data) => {
      const { accessToken, refreshToken } = data;

      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);

      const decoded = jwtDecode<User>(accessToken);

      const user = await getUser(decoded?.id);
      setUser(user);

      navigate(`${Route.Talents}/${user.id}`);
    },
    onError: console.error,
  });

  const onSubmit = (data: yup.InferType<typeof signInSchema>) => {
    mutate(data);
  };

  return (
    <AuthLayout>
      <h5>{t('welcome-back')}</h5>
      <form style={{ marginTop: '20px' }} onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          {...register('email')}
          error={errors?.email?.message}
          border
          placeholder={t('translation:email')}
        />
        <TextInput
          {...register('password')}
          error={errors?.password?.message}
          border
          placeholder={t('translation:password')}
          type="password"
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '12px',
          }}
        >
          <Link to={Route.ForgotPassword}>
            {t('translation:forgot-password')}
          </Link>
          <Button
            type="submit"
            variant="contained"
            size="small"
            disabled={isLoading}
          >
            {t('translation:sign-in')}
          </Button>
        </div>
        {isError && (
          <Alert variant="danger">
            {t(`error:${error.response.data.code}`)}
          </Alert>
        )}
      </form>
    </AuthLayout>
  );
};
