import { FC, useMemo, useState } from 'react';
import { Container } from '../../components/container';
import { Wrapper, LeftContent, RightContent } from './job.styles';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getJob } from '../../api/job';
import { Location } from '../../components/location';
import { TagList } from '../../components/tag-list';
import {
  Section,
  SectionContent,
  SectionHeader,
} from '../talent/talent.styles';
import { Button } from '../../components/form-elements/button';
import { Pen } from '../../components/icons/common/pen';
import { useUserContext } from '../../contexts/user';
import { JobForm } from '../jobs/components/job-form';

interface IProps {}

export const Job: FC<IProps> = () => {
  const [user] = useUserContext();
  const { jobId } = useParams<{ jobId: string }>();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    data: job,
    refetch,
    isLoading,
  } = useQuery(['job', jobId], () => getJob(jobId!), {
    onError: console.error,
    retry: false,
  });

  const isEditable = useMemo(() => {
    return user?.id === job?.user.id;
  }, [user?.id, job?.user.id]);

  if (isLoading) {
    return <Container>Loading...</Container>;
  }

  if (!job) {
    return <Container>Job not found</Container>;
  }

  return (
    <Container>
      {isModalOpen && isEditable && (
        <JobForm
          job={job}
          onSave={() => {
            setIsModalOpen(false);
            refetch();
          }}
          onCancel={() => setIsModalOpen(false)}
        />
      )}
      <Wrapper>
        <LeftContent>
          <h3>{job.position}</h3>
          <Section>
            <SectionHeader>
              <h4>About job</h4>
              {isEditable && (
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => setIsModalOpen(true)}
                >
                  <Pen />
                </Button>
              )}
            </SectionHeader>
            <SectionContent>
              <h5 style={{ fontStyle: 'italic' }}>{job.organization}</h5>
              <h6>Description</h6>
              <p>{job.description}</p>
              <Location location={job.location} />
              <TagList tags={[job.employmentType, job.workplaceType]} />
              <h6>Deadline</h6>
              <TagList tags={[job.deadline]} />
              {job.benefits && (
                <>
                  <h6>Benefits</h6>
                  <ul>
                    {job.benefits.map((benefit, index) => (
                      <li key={index}>{benefit.benefit}</li>
                    ))}
                  </ul>
                </>
              )}
            </SectionContent>
          </Section>
        </LeftContent>
        <RightContent>
          <Section>
            <SectionHeader>
              <h4>Job requirements</h4>
            </SectionHeader>
            <SectionContent>
              <h6>Skills</h6>
              <TagList tags={job.skills} />

              <h6>Responsibilities</h6>
              <ul>
                {job.responsibilities.map((responsibility, index) => (
                  <li key={index}>{responsibility.responsibility}</li>
                ))}
              </ul>
              <h6>Required Qualifications</h6>
              <ul>
                {job.qualifications.required.map((qualification, index) => (
                  <li key={index}>{qualification.qualification}</li>
                ))}
              </ul>
              {job.qualifications?.preferred && (
                <>
                  <h6>Preferred Qualifications</h6>

                  <ul>
                    {job.qualifications.preferred.map(
                      (qualification, index) => (
                        <li key={index}>{qualification.qualification}</li>
                      ),
                    )}
                  </ul>
                </>
              )}
            </SectionContent>
          </Section>
        </RightContent>
      </Wrapper>
    </Container>
  );
};
