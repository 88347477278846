import { forwardRef, InputHTMLAttributes, ReactNode } from 'react';
import { Option, SelectComponent, SelectError, Wrapper } from './select.styles';

export interface IProps extends InputHTMLAttributes<HTMLSelectElement> {
  children: ReactNode;
  border?: boolean;
  error?: string;
}

export const Select = forwardRef<HTMLSelectElement, IProps>((props, ref) => {
  return (
    <Wrapper>
      <SelectComponent
        {...props}
        ref={ref}
        border={!!props.border}
        error={!!props.error}
      >
        <Option value="" disabled>
          {props.placeholder}
        </Option>
        {props.children}
      </SelectComponent>
      {!!props.error && <SelectError>{props.error}</SelectError>}
    </Wrapper>
  );
});
