import axios from 'axios';
import { Gender } from '../enums/gender';

export type AuthResponse = {
  accessToken: string;
  refreshToken: string;
};

type SignInPayload = {
  email: string;
  password: string;
};

export const signIn = async (payload: SignInPayload): Promise<AuthResponse> => {
  const { data } = await axios.post<AuthResponse>('/auth/sign-in', payload);
  return data;
};

type SignUpPayload = {
  firstName: string;
  lastName: string;
  email: string;
  gender: Gender;
  password: string;
};

export const signUp = async (payload: SignUpPayload): Promise<AuthResponse> => {
  const { data } = await axios.post<AuthResponse>('/auth/sign-up', payload);
  return data;
};

export const signOut = async (refreshToken: string): Promise<void> => {
  return axios.post('/auth/sign-out', {
    refreshToken,
  });
};

export const refreshToken = async (token: string): Promise<AuthResponse> => {
  const { data } = await axios.post<AuthResponse>('/auth/refresh-token', {
    refreshToken: token,
  });
  return data;
};

export const forgotPassword = async (email: string) => {
  const { data } = await axios.post('/auth/forgot-password', { email });
  return data;
};

type ResetPasswordPayload = {
  id: string;
  token: string;
  password: string;
};

export const resetPassword = async (payload: ResetPasswordPayload) => {
  const { data } = await axios.post('/auth/reset-password', payload);
  return data;
};

type UpdatePasswordPayload = {
  email: string;
  password: string;
  newPassword: string;
};
export const updatePassword = async (payload: UpdatePasswordPayload) => {
  const { data } = await axios.post('/auth/update-password', payload);
  return data;
};

export const verifyEmail = async (id: string, token: string): Promise<void> => {
  await axios.post('/auth/verify-email', {
    id,
    token,
  });
};
