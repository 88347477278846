import { FC } from 'react';
import { Location as LocationType } from '../../types/location';
import { Location as LocationIcon } from '../icons/common/location';
import { Label, Wrapper } from './location.styles';

interface IProps {
  location: LocationType;
}

export const Location: FC<IProps> = ({ location }) => {
  if (!location.city) {
    return null;
  }
  return (
    <Wrapper>
      <LocationIcon width={16} />
      <Label>{`${location.city}, ${location.country}`}</Label>
    </Wrapper>
  );
};
