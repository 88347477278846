import styled, { css } from 'styled-components';
import { Breakpoint } from '../../enums/breakpoint';

export const Overlay = styled.div(
  ({ theme }) => css`
    position: fixed;
    z-index: ${theme.zIndex.modal};
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${theme.palette.common.overlay};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;

    ${theme.breakpoints.up(Breakpoint.Medium)} {
      padding: 16px;
    }
  `,
);

export const Wrapper = styled.div(
  () => css`
    width: 100%;
    height: 100%;
    overflow: auto scroll;
  `,
);

export const Content = styled.div(
  ({ theme }) => css`
    background: ${theme.palette.background.default};
    margin: 0 auto;
  `,
);
