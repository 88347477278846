import styled, { css } from 'styled-components';

export const Description = styled.p(
  () => css`
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  `,
);
