import { FC } from 'react';
import { useMutation } from 'react-query';
import { useMatch, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Route } from '../../enums/route';
import { ThemeStyle } from '../../enums/theme-style';
import { useUserContext } from '../../contexts/user';
import { useThemeStyleContext } from '../../contexts/theme-style';
import { Container } from '../container';
import { SidebarToggle } from '../sidebar-toggle';
import { Button } from '../form-elements/button';
import { Sun } from '../icons/common/sun';
import { Moon } from '../icons/common/moon';
import { User } from '../icons/common/user';
import {
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  DropdownToggle,
} from '../dropdown';
import { signOut } from '../../api/auth';
import { Wrapper, Content } from './header.styles';
import { Navbar, NavbarLink } from '../navbar';

export const Header: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [user, setUser] = useUserContext();
  const [themeStyle, setThemeStyle] = useThemeStyleContext();

  const signInMatch = useMatch(Route.SignIn);

  const { mutate: signOutFn } = useMutation(async () => {
    const refreshToken = localStorage.getItem('refreshToken');

    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');

    setUser(undefined);

    if (refreshToken) {
      try {
        await signOut(refreshToken);
      } catch (error: unknown) {
        console.error(error);
      }
    }

    navigate(Route.SignIn);
  });

  return (
    <Wrapper>
      <Container>
        <Content>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <SidebarToggle />

            <div id="navbar-portal" />
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <Button
              variant="text"
              onClick={() => {
                const style =
                  themeStyle === ThemeStyle.Dark
                    ? ThemeStyle.Light
                    : ThemeStyle.Dark;

                setThemeStyle(style);
                localStorage.setItem('themeStyle', style);
              }}
            >
              {themeStyle === ThemeStyle.Dark ? <Sun /> : <Moon />}
            </Button>
            {user !== undefined && (
              <Dropdown key="dropdown" placement="bottom-end">
                <DropdownToggle key="toggle" size="small">
                  <User />
                </DropdownToggle>
                <DropdownMenu key="menu">
                  <DropdownMenuItem
                    key="profile"
                    onClick={() => {
                      navigate(`${Route.Talents}/${user.id}`);
                    }}
                  >
                    {t('profile')}
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    key="insights"
                    onClick={() => {
                      navigate(`${Route.Talents}/${user.id}/insights`);
                    }}
                  >
                    {t('insights')}
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    key="settings"
                    onClick={() => {
                      navigate(Route.Settings);
                    }}
                  >
                    {t('settings')}
                  </DropdownMenuItem>
                  <DropdownMenuItem key="sign-out" onClick={() => signOutFn()}>
                    {t('sign-out')}
                  </DropdownMenuItem>
                </DropdownMenu>
              </Dropdown>
            )}

            {user === undefined && signInMatch && (
              <Navbar>
                <NavbarLink to={Route.SignUp} variant="contained">
                  {t('sign-up')}
                </NavbarLink>
              </Navbar>
            )}
            {user === undefined && !signInMatch && (
              <Navbar>
                <NavbarLink to={Route.SignIn} variant="contained">
                  {t('sign-in')}
                </NavbarLink>
              </Navbar>
            )}
          </div>
        </Content>
      </Container>
    </Wrapper>
  );
};
