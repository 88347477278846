import * as yup from 'yup';
import { locationSchema } from './location.schema';
import { languageSchema } from './language.schema';
import { MIN_SKILL_LENGTH } from '../constants/skill';

export const portfolioSchema = yup
  .object({
    id: yup.string(),
    occupation: yup.string().required(),
    location: locationSchema.required(),
    headline: yup.string().required(),
    skills: yup
      .array()
      .of(yup.string().required())
      .min(MIN_SKILL_LENGTH)
      .required(),
    languages: yup.array().of(languageSchema).required(),
  })
  .required();
