import dayjs from 'dayjs';
import { Job } from '../types/job';
import { EmploymentType } from '../enums/employment-type';
import { WorkplaceType } from '../enums/workplace-type';

export const MIN_RESPONSIBILITIES_NUMBER = 3;
export const MAX_RESPONSIBILITIES_NUMBER = 5;
export const QUALIFICATION_MIN_LENGTH = 10;
export const QUALIFICATION_MAX_LENGTH = 200;
export const MIN_REQUIRED_QUALIFICATIONS_NUMBER = 1;
export const MAX_REQUIRED_QUALIFICATIONS_NUMBER = 5;
export const MIN_PREFERRED_QUALIFICATIONS_NUMBER = 0;
export const MAX_PREFERRED_QUALIFICATIONS_NUMBER = 5;
export const BENEFIT_MIN_LENGTH = 10;
export const BENEFIT_MAX_LENGTH = 300;
export const MIN_BENEFITS_NUMBER = 1;
export const MAX_BENEFITS_NUMBER = 5;

export const EMPTY_JOB: Omit<Job, 'id' | 'user'> = {
  organization: '',
  employmentType: EmploymentType.FULL_TIME,
  workplaceType: WorkplaceType.ONSITE,
  position: '',
  location: {
    city: '',
    country: '',
  },
  qualifications: {
    required: Array(MIN_REQUIRED_QUALIFICATIONS_NUMBER).fill({
      qualification: '',
    }),
    preferred: [],
  },
  description: '',
  responsibilities: Array(MIN_RESPONSIBILITIES_NUMBER).fill({
    responsibility: '',
  }),
  deadline: dayjs().add(1, 'week').format(process.env.REACT_APP_DATE_FORMAT),
  benefits: Array(MIN_BENEFITS_NUMBER).fill({
    benefit: '',
  }),
  skills: [],
};
