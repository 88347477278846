import styled, { css } from 'styled-components';

export const Wrapper = styled.div(
  () => css`
    display: flex;
    align-items: baseline;
  `,
);

export const Label = styled.p(
  () => css`
    margin-inline-start: 8px;
  `,
);
