import axios from 'axios';
import { Language, ProficiencyLevel } from '../types/language';

export const getLanguages = async (): Promise<{
  languages: Language[];
  proficiencyLevels: ProficiencyLevel[];
}> => {
  const { data } = await axios.get<{
    languages: Language[];
    proficiencyLevels: ProficiencyLevel[];
  }>('/languages');
  return data;
};
