import { createGlobalStyle, css } from 'styled-components';

export const GlobalStyle = createGlobalStyle(
  ({ theme }) => css`
    * {
      margin: 0;
      padding: 0;
      margin-inline-start: 0;
      margin-inline-end: 0;
      margin-block-start: 0;
      margin-block-end: 0;
      font-size: 14px;
      font-family: 'Roboto', sans-serif;
    }

    *,
    :after,
    :before {
      box-sizing: border-box;
    }

    body {
      font:
        ${theme.typography.p} 'Roboto',
        sans-serif;
      color: ${theme.palette.text.primary};
      background-color: ${theme.palette.background.default};
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      margin-bottom: 12px;
    }

    h1 {
      font-size: ${theme.typography.h1};
      letter-spacing: -1.5px;
    }

    h2 {
      font-size: ${theme.typography.h2};
      letter-spacing: -0.5px;
    }

    h3 {
      font-size: ${theme.typography.h3};
      letter-spacing: 0;
    }

    h4 {
      font-size: ${theme.typography.h4};
      letter-spacing: 0.25px;
    }

    h5 {
      font-size: ${theme.typography.h5};
      letter-spacing: 0;
    }

    h6 {
      font-size: ${theme.typography.h6};
      letter-spacing: 0.15px;
    }

    p {
      letter-spacing: 0.25px;
    }

    a {
      color: ${theme.palette.text.primary};
      text-decoration: none;

      &:hover {
        color: ${theme.palette.secondary.dark};
      }
    }

    hr {
      border-color: ${theme.palette.divider};
      margin-bottom: 12px;
    }

    ul {
      padding-inline-start: 12px;
      margin-bottom: 12px;
    }
  `,
);
