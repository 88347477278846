import axios from 'axios';
import { Job } from '../types/job';

export const getJob = async (id: string): Promise<Job> => {
  const { data } = await axios.get<Job>(`/jobs/${id}`);
  return data;
};

export const getJobs = async (
  page: number,
  limit: number,
  filters?: Record<string, string | string[]>,
): Promise<{
  data: Job[];
  total: number;
  page: number;
  limit: number;
}> => {
  const { data } = await axios.get<{
    data: Job[];
    total: number;
    page: number;
    limit: number;
  }>(`/jobs`, {
    params: {
      page,
      limit,
      ...filters,
    },
  });
  return data;
};

export const createJob = async (
  payload: Omit<Job, 'id' | 'user'>,
): Promise<Job> => {
  const { data } = await axios.post<Job>(`/jobs`, payload);
  return data;
};

export const updateJob = async (payload: Job): Promise<Job> => {
  const { data } = await axios.put<Job>(`/jobs/${payload.id}`, payload);
  return data;
};

export const deleteJob = async (id: string): Promise<{ message: string }> => {
  const { data } = await axios.delete<{ message: string }>(`/jobs/${id}`);
  return data;
};
