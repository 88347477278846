import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { getUserInsights } from '../../api/user';
import { Route } from '../../enums/route';
import { Insights as InsightsType } from '../../types/insight';
import { DoughnutChart } from '../../components/charts/doughnut';
import { Container } from '../../components/container';
import { Navbar, NavbarPortal, NavbarLink } from '../../components/navbar';
import { Widget, Wrapper } from './insights.styles';

export const Insights = () => {
  const { t } = useTranslation();
  const { userId } = useParams<{ userId: string }>();

  const { data, isLoading } = useQuery<{
    experience: InsightsType;
    language: InsightsType;
  }>(['insights', userId], () => getUserInsights(userId!), {
    onError: console.error,
    retry: false,
  });

  const experienceInsights = useMemo(() => {
    if (!data || !data.experience) {
      return null;
    }

    return {
      labels: Object.keys(data.experience),
      datasets: [
        {
          data: Object.values(data.experience),
        },
      ],
    };
  }, [data]);

  const languageInsights = useMemo(() => {
    if (!data || !data.language) {
      return null;
    }

    return {
      labels: Object.keys(data.language),
      datasets: [
        {
          data: Object.values(data.language),
        },
      ],
    };
  }, [data]);

  if (isLoading) {
    return (
      <Container>
        <Wrapper>Loading...</Wrapper>
      </Container>
    );
  }

  return (
    <>
      <NavbarPortal>
        <Navbar>
          <NavbarLink to={`${Route.Talents}/${userId}`} end>
            {t('profile')}
          </NavbarLink>
          <NavbarLink to={`${Route.Talents}/${userId}/insights`}>
            {t('insights')}
          </NavbarLink>
        </Navbar>
      </NavbarPortal>
      <Container>
        <Wrapper>
          {experienceInsights && (
            <Widget.Wrapper>
              <Widget.Header>Skills</Widget.Header>
              <Widget.Body>
                <DoughnutChart
                  labels={experienceInsights.labels}
                  datasets={experienceInsights.datasets}
                />
              </Widget.Body>
            </Widget.Wrapper>
          )}
          {languageInsights && (
            <Widget.Wrapper>
              <Widget.Header>Languages</Widget.Header>
              <Widget.Body>
                <DoughnutChart
                  labels={languageInsights.labels}
                  datasets={languageInsights.datasets}
                />
              </Widget.Body>
            </Widget.Wrapper>
          )}
        </Wrapper>
      </Container>
    </>
  );
};
