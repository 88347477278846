import { Children, FC, isValidElement, ReactNode } from 'react';
import { NavbarLink, NavbarWrapper } from './navbar.styles';

interface IProps {
  children: ReactNode;
}

export const Navbar: FC<IProps> = ({ children }) => {
  return (
    <NavbarWrapper>
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          switch (child.type) {
            case NavbarLink:
              return child;
            default:
              return null;
          }
        }
        return null;
      })}
    </NavbarWrapper>
  );
};
