import * as yup from 'yup';
import { MIN_PASSWORD_LENGTH } from '../constants/password';

export const resetPasswordSchema = yup
  .object({
    password: yup.string().required().min(MIN_PASSWORD_LENGTH),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password')])
      .required(),
  })
  .required();
