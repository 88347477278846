import { FC, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { getPortfolios } from '../../api/portfolio';
import { Container } from '../../components/container';
import { Pagination } from '../../components/pagination';
import { Tags } from '../../components/tags';
import { Portfolio } from '../../types/portfolio';
import { Content, Filters, Wrapper } from './talents.styles';
import { Talent } from './components/talent';

export const Talents: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const skillsQuery = searchParams.getAll('skills');
  const languagesQuery = searchParams.getAll('languages');
  const pageQuery = Number(searchParams.get('page') ?? '1');

  const filters = useMemo(() => {
    const obj: Record<string, string | string[]> = {};
    if (skillsQuery.length > 0) {
      obj.skills = skillsQuery;
    }
    if (languagesQuery.length > 0) {
      obj.languages = languagesQuery;
    }
    return obj;
  }, [skillsQuery, languagesQuery]);

  const { isLoading, data } = useQuery<{
    data: Portfolio[];
    total: number;
    page: number;
    limit: number;
  }>(
    ['portfolios', filters, pageQuery],
    () => getPortfolios(pageQuery, 5, filters),
    {
      onError: console.error,
      retry: false,
    },
  );

  return (
    <Container>
      <Wrapper>
        <Filters>
          <h5>Filter by</h5>
          <Tags
            placeholder="Skills"
            tags={skillsQuery}
            onChange={(tags) => {
              setSearchParams({
                ...filters,
                skills: tags,
              });
            }}
          />
          <Tags
            placeholder="Languages"
            tags={languagesQuery}
            onChange={(tags) => {
              setSearchParams({
                ...filters,
                languages: tags,
              });
            }}
          />
        </Filters>
        <Content>
          {isLoading && <div>Loading...</div>}
          {data && (
            <>
              {data.data.map((portfolio) => {
                return <Talent key={portfolio.id} portfolio={portfolio} />;
              })}
              <Pagination
                onPageChange={(page) => {
                  setSearchParams({
                    ...filters,
                    page: page.toString(),
                  });
                }}
                total={data.total}
                limit={data.limit}
                currentPage={pageQuery}
              />
            </>
          )}
        </Content>
      </Wrapper>
    </Container>
  );
};
