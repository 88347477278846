import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { Language } from '../enums/language';

export const initI18n = async (): Promise<void> => {
  await i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      detection: {
        order: ['path'],
        lookupFromPathIndex: 0,
        caches: [],
      },
      fallbackLng: Language.English,
      supportedLngs: [Language.English],
      interpolation: {
        escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      },
      react: {
        useSuspense: true,
      },
    });
};

export const changeLanguage = async (lang: Language) => {
  const urlFragments = window.location.pathname
    .split('/')
    .filter((fragment) => fragment !== '');
  if (Object.values(Language).includes(urlFragments[0] as Language)) {
    urlFragments[0] = lang;
    window.location.assign(`/${urlFragments.join('/')}`);
  } else {
    window.location.assign(`/${[lang, ...urlFragments].join('/')}`);
  }
};
