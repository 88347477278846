import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { useSidebarContext } from '../../contexts/sidebar';
import { Route } from '../../enums/route';
import { Briefcase } from '../icons/common/briefcase';
import { Talent } from '../icons/common/talent';
import { Home } from '../icons/common/home';
import { SidebarToggle } from '../sidebar-toggle';
import {
  Wrapper,
  Nav,
  NavContent,
  NavLinkWrapper,
  NavItemLabel,
  Brand,
  NavHeader,
} from './sidebar.styles';

const NavLink: FC<{ to: string; children: ReactNode }> = ({ to, children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useSidebarContext();

  return (
    <NavLinkWrapper
      to={to}
      onClick={() => {
        if (isSidebarOpen) {
          setIsSidebarOpen(false);
        }
      }}
    >
      {children}
    </NavLinkWrapper>
  );
};

export const Sidebar: FC = () => {
  const { t } = useTranslation();
  const [isSidebarOpen] = useSidebarContext();

  return (
    <Wrapper open={isSidebarOpen}>
      <Nav>
        <NavHeader>
          <SidebarToggle />
          <NavLink to={Route.Home}>
            <Brand>{process.env.REACT_APP_TITLE}</Brand>
          </NavLink>
        </NavHeader>
        <NavContent>
          <NavLink to={Route.Home}>
            <Home /> <NavItemLabel>{t('home')}</NavItemLabel>
          </NavLink>
          <NavLink to={Route.Talents}>
            <Talent /> <NavItemLabel>{t('talents')}</NavItemLabel>
          </NavLink>
          <NavLink to={Route.Jobs}>
            <Briefcase /> <NavItemLabel>{t('jobs')}</NavItemLabel>
          </NavLink>
        </NavContent>
      </Nav>
    </Wrapper>
  );
};
