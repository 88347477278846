import * as yup from 'yup';

import { EmploymentType } from '../enums/employment-type';
import { WorkplaceType } from '../enums/workplace-type';
import {
  BENEFIT_MIN_LENGTH,
  BENEFIT_MAX_LENGTH,
  MAX_PREFERRED_QUALIFICATIONS_NUMBER,
  MAX_REQUIRED_QUALIFICATIONS_NUMBER,
  MAX_RESPONSIBILITIES_NUMBER,
  MIN_REQUIRED_QUALIFICATIONS_NUMBER,
  MIN_RESPONSIBILITIES_NUMBER,
  QUALIFICATION_MIN_LENGTH,
  QUALIFICATION_MAX_LENGTH,
  MIN_PREFERRED_QUALIFICATIONS_NUMBER,
} from '../constants/job';
import {
  RESPONSIBILITY_MIN_LENGTH,
  RESPONSIBILITY_MAX_LENGTH,
} from '../constants/experience';
import { locationSchema } from './location.schema';
import { MIN_SKILL_LENGTH } from '../constants/skill';

export const jobSchema = yup.object({
  id: yup.string(),
  position: yup.string().required(),
  organization: yup.string().required(),
  employmentType: yup.string().oneOf(Object.values(EmploymentType)).required(),
  workplaceType: yup.string().oneOf(Object.values(WorkplaceType)).required(),
  location: locationSchema,
  description: yup.string().required(),
  qualifications: yup
    .object({
      required: yup
        .array()
        .of(
          yup.object({
            qualification: yup
              .string()
              .min(QUALIFICATION_MIN_LENGTH)
              .max(QUALIFICATION_MAX_LENGTH)
              .required(),
          }),
        )
        .min(MIN_REQUIRED_QUALIFICATIONS_NUMBER)
        .max(MAX_REQUIRED_QUALIFICATIONS_NUMBER)
        .required(),
      preferred: yup
        .array()
        .of(
          yup.object({
            qualification: yup
              .string()
              .min(QUALIFICATION_MIN_LENGTH)
              .max(QUALIFICATION_MAX_LENGTH)
              .required(),
          }),
        )
        .min(MIN_PREFERRED_QUALIFICATIONS_NUMBER)
        .max(MAX_PREFERRED_QUALIFICATIONS_NUMBER),
    })
    .required(),
  responsibilities: yup
    .array()
    .of(
      yup.object({
        responsibility: yup
          .string()
          .min(RESPONSIBILITY_MIN_LENGTH)
          .max(RESPONSIBILITY_MAX_LENGTH)
          .required(),
      }),
    )
    .min(MIN_RESPONSIBILITIES_NUMBER)
    .max(MAX_RESPONSIBILITIES_NUMBER)
    .required(),
  deadline: yup.string().required(),
  benefits: yup
    .array()
    .of(
      yup.object({
        benefit: yup
          .string()
          .min(BENEFIT_MIN_LENGTH)
          .max(BENEFIT_MAX_LENGTH)
          .required(),
      }),
    )
    .required(),
  skills: yup
    .array()
    .of(yup.string().required())
    .min(MIN_SKILL_LENGTH)
    .required(),
});
