import styled, { css } from 'styled-components';
import { Breakpoint } from '../../enums/breakpoint';

export const Wrapper = styled.div(
  () => css`
    margin: 16px auto;
  `,
);

export const KeyPointsContainer = styled.section(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 12px;

    ${theme.breakpoints.up(Breakpoint.Medium)} {
      grid-template-columns: repeat(2, 1fr);
    }
  `,
);

export const KeyPointsTitle = styled.h4(
  ({ theme }) => css`
    color: ${theme.palette.secondary.main};
    margin-top: 48px;
  `,
);
