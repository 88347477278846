import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { Job as JobType } from '../../types/job';
import { getJobs } from '../../api/job';
import { Container } from '../../components/container';
import { Tags } from '../../components/tags';
import { Button } from '../../components/form-elements/button';
import { JobForm } from './components/job-form';
import { Content, Filters, Wrapper } from './jobs.styles';
import { useUserContext } from '../../contexts/user';
import { EMPTY_JOB } from '../../constants/job';
import { Pagination } from '../../components/pagination';
import { Job } from './components/job';

export const Jobs = () => {
  const [user] = useUserContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const organizationQuery = searchParams.getAll('organization');
  const positionQuery = searchParams.getAll('position');
  const pageQuery = Number(searchParams.get('page') ?? '1');
  const [job, setJob] = useState<
    (Omit<JobType, 'id'> & { id?: string }) | null
  >(null);

  const filters = useMemo(() => {
    const obj: Record<string, string | string[]> = {};
    if (organizationQuery.length > 0) {
      obj.organization = organizationQuery;
    }
    if (positionQuery.length > 0) {
      obj.languages = positionQuery;
    }
    return obj;
  }, [organizationQuery, positionQuery]);

  const { isLoading, data, refetch } = useQuery<{
    data: JobType[];
    total: number;
    page: number;
    limit: number;
  }>(['jobs', filters, pageQuery], () => getJobs(pageQuery, 5, filters), {
    onError: console.error,
    retry: false,
  });

  return (
    <Container>
      <Wrapper>
        <Filters>
          {user && (
            <div
              style={{
                marginBottom: '16px',
              }}
            >
              <Button
                variant="contained"
                size="small"
                style={{
                  width: '100%',
                }}
                onClick={() =>
                  setJob({
                    ...EMPTY_JOB,
                    id: undefined,
                    user: {
                      id: user.id,
                      firstName: user.firstName,
                      lastName: user.lastName,
                    },
                  })
                }
              >
                Post a Job
              </Button>
            </div>
          )}
          <h5>Filter by</h5>
          <Tags
            placeholder="Organization"
            tags={organizationQuery}
            onChange={(tags) => {
              setSearchParams({
                ...filters,
                organization: tags,
              });
            }}
          />
          <Tags
            placeholder="Position"
            tags={positionQuery}
            onChange={(tags) => {
              setSearchParams({
                ...filters,
                position: tags,
              });
            }}
          />
        </Filters>
        <Content>
          {isLoading && <div>Loading...</div>}
          {data && (
            <>
              {data.data.map((job) => {
                return <Job key={job.id} job={job} />;
              })}
              <Pagination
                onPageChange={(page) => {
                  setSearchParams({
                    ...filters,
                    page: page.toString(),
                  });
                }}
                total={data.total}
                limit={data.limit}
                currentPage={pageQuery}
              />
            </>
          )}
        </Content>
        {job && (
          <JobForm
            job={job}
            onCancel={() => setJob(null)}
            onSave={() => {
              setJob(null);
              refetch();
            }}
          />
        )}
      </Wrapper>
    </Container>
  );
};
