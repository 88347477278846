import { FC } from 'react';
import { Wrapper, Tag, RemoveButton } from './tag-list.styles';

interface IProps {
  tags: string[];
  removeTag?: (tag: string) => void;
}

export const TagList: FC<IProps> = ({ tags, removeTag }) => {
  if (!tags.length) {
    return null;
  }

  return (
    <Wrapper>
      {tags.map((tag) => {
        return (
          <Tag key={tag}>
            {tag}
            {removeTag && (
              <RemoveButton onClick={() => removeTag(tag)}>
                &times;
              </RemoveButton>
            )}
          </Tag>
        );
      })}
    </Wrapper>
  );
};
