import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../components/form-elements/button';
import { Pen } from '../../../components/icons/common/pen';
import { TagList } from '../../../components/tag-list';
import { Portfolio } from '../../../types/portfolio';
import { Location } from '../../../components/location';
import { Section, SectionContent, SectionHeader } from '../talent.styles';
import { AboutForm } from './about-form';

interface IProps {
  portfolio?: Portfolio;
  isEditable?: boolean;
  refetch: () => void;
}

export const About: FC<IProps> = ({ portfolio, isEditable, refetch }) => {
  const { t } = useTranslation('profile');
  const [isModalOpen, setIsModalOpen] = useState(false);

  if (!portfolio) {
    if (!isEditable) {
      return null;
    }

    return (
      <>
        <Button
          variant="contained"
          size="small"
          onClick={() => setIsModalOpen(true)}
        >
          Create profile
        </Button>
        {isModalOpen && isEditable && (
          <AboutForm
            portfolio={portfolio}
            onCancel={() => setIsModalOpen(false)}
            onSave={() => {
              setIsModalOpen(false);
              refetch();
            }}
          />
        )}
      </>
    );
  }

  return (
    <>
      <h3>{`${portfolio?.user?.firstName} ${portfolio?.user?.lastName}`}</h3>
      <Section>
        <SectionHeader>
          <h4>{t('about.title')}</h4>
          {isEditable && (
            <Button
              variant="outlined"
              size="small"
              onClick={() => setIsModalOpen(true)}
            >
              <Pen />
            </Button>
          )}
        </SectionHeader>
        <SectionContent>
          <h5>{portfolio.occupation}</h5>
          <Location location={portfolio.location} />
          <p>{portfolio.headline}</p>
          <h6>{t('translation:skills.title')}</h6>
          <TagList tags={portfolio.skills} />
          <h6>{t('languages.title')}</h6>
          <TagList
            tags={portfolio.languages.map((language) => {
              return language.language;
            })}
          />
        </SectionContent>
      </Section>
      {isModalOpen && isEditable && (
        <AboutForm
          portfolio={portfolio}
          onCancel={() => setIsModalOpen(false)}
          onSave={() => {
            setIsModalOpen(false);
            refetch();
          }}
        />
      )}
    </>
  );
};
