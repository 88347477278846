import * as yup from 'yup';
import { locationSchema } from './location.schema';

export const educationSchema = yup.object({
  id: yup.string(),
  institution: yup.string().required(),
  degree: yup.string().required(),
  fieldOfStudy: yup.string().required(),
  location: locationSchema,
  tenure: yup
    .object({
      from: yup.string().required(),
      to: yup.string(),
    })
    .required(),
});
