import { FC } from 'react';
import { Job as JobType } from '../../../types/job';
import { Description } from './job.styles';
import { Briefcase as BriefcaseIcon } from '../../../components/icons/common/briefcase';
import { Location } from '../../../components/location';
import { Route } from '../../../enums/route';
import { TagList } from '../../../components/tag-list';
import { Card } from '../../../components/card';

interface IProps {
  job: JobType;
}

export const Job: FC<IProps> = ({ job }) => {
  return (
    <Card
      to={`${Route.Jobs}/${job.id}`}
      picture={<BriefcaseIcon width={40} />}
      content={
        <>
          <h3>{job.position}</h3>
          <Description>{job.description}</Description>
          <TagList tags={job.skills.slice(0, 5)} />
          <Location location={job.location} />
        </>
      }
    />
  );
};
